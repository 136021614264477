/// <reference path="../../types/leaflet-polylinedecorator.d.ts" />

import * as L from 'leaflet'
import * as LeafletPolylineDecorator from 'leaflet-polylinedecorator';
import * as Collections from 'typescript-collections'

import { MQTTConnector, MQTTMessage } from '../dsh-mqtt'
import { ndwMap, suppressedStreamsByQuadkey } from '../ndw-app';

//to force the import of the modules
LeafletPolylineDecorator;

export interface TalkingTrafficMarker extends L.RotatedMarker {
    quadkey?: string;
}

/**
 * A handler which applies layer specific configuration on a marker.
 */
export type LayerSpecificMarkerHandler = (quadkey: string, identifier: string, marker: TalkingTrafficMarker,
                                          options: L.RotatedMarkerOptions) => void

export abstract class InfoLayer {
    layer: any
    markers: Collections.Dictionary<String, L.RotatedMarker>
    markersByQuadKey: Collections.Dictionary<String, Collections.Dictionary<String, L.RotatedMarker>>
    geoBaseTopics: Array<string>
    nonGeoBaseTopics: Array<string>
    currentSubscriptionTopics: Collections.Set<String>
    visible: boolean
    region: Collections.Set<String>
    mqttClient: MQTTConnector
    subscriptionUpdateCallback: (subscription: string) => void

    isAccessible: boolean = false

    constructor(map: L.Map, geoBaseTopics: Array<string>, nonGeoBaseTopics: Array<string>, client: MQTTConnector, quadKeys: boolean = true) {
        this.mqttClient = client
        this.geoBaseTopics = geoBaseTopics
        this.nonGeoBaseTopics = nonGeoBaseTopics
        this.layer = L.layerGroup([]).addTo(map)
        this.markers = new Collections.Dictionary<String, L.RotatedMarker>()
        this.markersByQuadKey = new Collections.Dictionary<String, Collections.Dictionary<String, L.RotatedMarker>>()
        this.currentSubscriptionTopics = new Collections.Set<String>()
        this.visible = true
        this.region = new Collections.Set<String>()
    }

    abstract onMessage(message: MQTTMessage): void
    abstract onMarkerRemoval(identifier: string): void

    wantMessage(msg: MQTTMessage): boolean {

      let matcher = (baseTopic: string) => {
        let topicMatch = msg.topic.startsWith(baseTopic);

        // check if msg topic quadkey doesn't exist in suppressed quadkeys
        const splitTopic = msg.topic.split('/');

        splitTopic.shift() // remove empty element
        splitTopic.shift() // remove tt
        splitTopic.shift() // remove stream name

        const key = splitTopic
          .slice(0,12) // cut off possible suffix identifiers
          .join('')

        let emergencyMessage = false;
        if (msg.topic.includes('prio-alerts') || msg.topic.includes('suppressions')) {
          emergencyMessage = true
        }

        let suppressedStream = false;
        const quadkeyWithSuppresion = suppressedStreamsByQuadkey
          .keys()
          .find(identifier => identifier.includes(key.substr(0,12)))

        if (quadkeyWithSuppresion) {
          suppressedStream = true
          const suppressedQuadkeyStreams = suppressedStreamsByQuadkey.getValue(quadkeyWithSuppresion)
          const topic = msg.topic.split('/')[2];
          if (!suppressedQuadkeyStreams.includes(topic)) {
            // topic is in a suppressed quadkey, but the stream is not suppressed
            suppressedStream = false;
          }
        }
        // we always want emergency messages even if in a suppressed quadkey
        return topicMatch && (!suppressedStream || emergencyMessage)

      }

      return this.geoBaseTopics.some(matcher) || this.nonGeoBaseTopics.some(matcher)
  }

    setVisible(visible: boolean): void {
        this.visible = visible
        this.updateSubscriptionsAndMarkers()
    }

    addToMarkersByQuadKey(quadkey: string, identifier: string, marker: L.RotatedMarker): void {
        if(!this.markersByQuadKey.containsKey(quadkey)) {
            this.markersByQuadKey.setValue(quadkey, new Collections.Dictionary<String, L.RotatedMarker>())
        }
        this.markersByQuadKey.getValue(quadkey).setValue(identifier, marker)
    }

    quadKeyFromTopic(topic: string, precision: number): string {
        for (let baseTopic of this.geoBaseTopics) {
            if (!topic.startsWith(baseTopic)) continue

            let baseTopicDepth = baseTopic.split("/").length
            let splitTopic = topic.split("/")
            // topics look like '/tt/ndwspeed/1/0/2/2/0/2/1/3/2/2/2/1/RWS01_MONIBAS_0121hrr0089ra/lane2'.
            // from this topic string take the first part of the quadkey
            return splitTopic.slice(baseTopicDepth, baseTopicDepth + precision).join("")
        }
        return null
    }

    setVisibleRegion(quadkeys: Collections.Set<String>): void {
        this.region = quadkeys
        this.updateSubscriptionsAndMarkers()
    }

    updateSubscriptionsAndMarkers(): void {
        // if we're invisible, just subscribe to an empty region
        let quadkeys = this.visible ? this.region : new Collections.Set<String>()
        let newSubscriptions = new Collections.Set<String>()

        //subscribe on each new quadKey
        let usesQuadKeys = true;
        for (let baseTopic of this.geoBaseTopics) {
          const splitTopic = baseTopic.split('/');
            if (splitTopic[2].includes('-spat')) { return }
            else {
              quadkeys.forEach((currentQuadKey: string) => {
              // dont subscribe on suppressed quadkeys
              const quadkeyWithSuppresion = suppressedStreamsByQuadkey
                  .keys()
                  .find(identifier => identifier.includes(currentQuadKey))
                if (!quadkeyWithSuppresion) {
                  newSubscriptions = this.createSubscription(baseTopic, currentQuadKey, newSubscriptions)
                } else {
                  // subscribe if suppressed quadkey's suppressed streams does not contain the basetopic
                  const suppressedQuadkeyStreams = suppressedStreamsByQuadkey.getValue(quadkeyWithSuppresion)
                  if (!suppressedQuadkeyStreams.includes(splitTopic[2])) {
                    // topic is in a suppressed quadkey, but the stream is not suppressed
                    newSubscriptions = this.createSubscription(baseTopic, currentQuadKey, newSubscriptions)
                  }
                }
              })
            }
        }
         // in case of 5G intersection messages
         // only SPaT messages aren't quadkey bound but MAP messages are in the same layer
        if (!usesQuadKeys) {
          if (!this.visible) { ndwMap.removeLayer(this.layer);}
          else { ndwMap.addLayer(this.layer); }
          return
        }

        this.currentSubscriptionTopics.forEach((topic: string) => {
            //unsubscribe from all quadkeys that fall outside the region
            if(!newSubscriptions.contains(topic)) {
                this.mqttClient.unsubscribe(topic)
                this.currentSubscriptionTopics.remove(topic)
            }
            // unsubscribe from all suppressed quadkeys
            const splitTopic = topic.split('/');
            const id = splitTopic.pop(); // remove #
            splitTopic.shift(); // remove empty element
            splitTopic.shift(); // remove tt
            splitTopic.shift(); // remove stream name
            const key = splitTopic.join('');
            const quadkeyHasSuppression = suppressedStreamsByQuadkey
              .keys()
              .some(identifier => identifier.includes(key))
            if (quadkeyHasSuppression) {
              this.mqttClient.unsubscribe(topic)
            }
        })

        // drop all markers that are not part of the visible region
        // or are part of a suppressed quadkey
        this.markersByQuadKey.forEach((quadKeyOfRecord: string, markersByName: Collections.Dictionary<String, L.RotatedMarker>) => {
          const quadkeyHasSuppression = suppressedStreamsByQuadkey
            .keys()
            .some(identifier => identifier.includes(quadKeyOfRecord))
            if(!quadkeys.contains(quadKeyOfRecord) || quadkeyHasSuppression) {
                markersByName.forEach((identifier: string, marker: L.RotatedMarker) => {
                    this.markers.remove(identifier)
                    this.onMarkerRemoval(identifier)
                    if (marker) { this.layer.removeLayer(marker) }
                })
                this.markersByQuadKey.remove(quadKeyOfRecord)
            }
        })
    }

    createSubscription(
      baseTopic: string,
      currentQuadKey: string,
      newSubscriptions: Collections.Set<String>
    ): Collections.Set<String> {
      const newSubscriptionTopic = baseTopic + "/" + currentQuadKey.split('').join('/') + "/#"
      newSubscriptions.add(newSubscriptionTopic)
      this.mqttClient.subscribe(newSubscriptionTopic)
      this.currentSubscriptionTopics.add(newSubscriptionTopic)
      this.subscriptionUpdateCallback(newSubscriptionTopic);
      return newSubscriptions;
    }

    setMarker(
      quadkey: string,
      identifier: string,
      lat: number,
      lng: number,
      info: any,
      options: L.RotatedMarkerOptions,
      layerSpecificMarkerHandler?: LayerSpecificMarkerHandler): void {

      const regionDoesntContainQuadkey = !this.region.contains(quadkey)
      if (quadkey && (!this.visible || regionDoesntContainQuadkey)) {
          // ignore because we're either invisible or the key is outside of the visible region
          // in other words, we got a spurious update
          return
      }
      let marker: TalkingTrafficMarker;
      if (this.markers.containsKey(identifier)) {
        marker = this.markers.getValue(identifier)
      } else if (lat && lng) {
        marker = L.rotatedMarker(L.latLng(lat, lng), options);
        this.layer.addLayer(marker);
        this.markers.setValue(identifier, marker)
        this.addToMarkersByQuadKey(quadkey, identifier, marker)
        marker.bindPopup(L.popup({maxHeight: 600, maxWidth: 400}).setContent(""))
      }
      if (marker) {
        marker.bindPopup(info);
        // some messages (such as spat) have no coords
        if (lat && lng) { marker.setLatLng(L.latLng(lat, lng)) }
        if (layerSpecificMarkerHandler) {
          layerSpecificMarkerHandler(quadkey, identifier, marker, options)
        } else if (options.icon) {
          marker.setIcon(options.icon);
        }
        if (options.angle) { marker.setAngle(options.angle); }
      }
    }

    removeMarker(identifier: string) {
      const marker = this.markers.getValue(identifier);
      if (!marker) return;
      this.markers.remove(identifier);
      this.layer.removeLayer(marker);
    }

    onSubscriptionUpdate(callback: (subscription: string) => void): void {
        this.subscriptionUpdateCallback = callback;
    }
}


