import * as L from 'leaflet'
import { MQTTConnector, MQTTMessage } from '../dsh-mqtt'
import { Config } from '../config'
import * as moment from 'moment'
import { InfoLayer } from './info-layer'

export class AirPollutionInfoLayer extends InfoLayer {
    visible = false

    constructor(map: L.Map, client: MQTTConnector, private config: Config, private regionPrecision: number) {
        super(map, [config.mqtt.airPollutionTopic], [], client)
    }

    onMarkerRemoval(identifier: string): void {
    }

    onMessage(message: MQTTMessage): void {
        function iconImage(rating: number, size: number): string {
            let iconstyle = `width:${size}px`
            if (rating > 0) {
                let huerotation = rating * 25 + 35
                iconstyle += `;filter: sepia(100%) hue-rotate(${huerotation}deg) contrast(200%) saturate(3)`
            }
            return `<img style="${iconstyle}" src="/images/air-pollution.png"/>`
        }

        function ratingLabel(rating: number): string {
            let i = Math.min(Math.max(0, rating), 11)
            let label = ['None', 'Good', 'Good', 'Good', 'Average', 'Average', 'Average', 'Insufficient', 'Insufficient', 'Bad'][i]
            return `${label} (${rating})`
        }

        function formatReading(reading: string): string {
            let rs = reading.split(':')
            let name = rs[0].trim()
            let value = rs[1]
            let fullname = componentLegend(name)
            let start = value.indexOf('(') + 1
            let after = value.indexOf(')')
            if (start > 0 && after > start) {
                let v = value.substring(start, after)
                value = iconImage(+v, 10) + ' ' + value.substring(0, start - 1) + ' <small>' + fullname + '</small>'
            } else {
                value = iconImage(0, 10) + ' ' + value + ' <small>' + fullname + '</small>'
            }
            return `<br/><span class="infolabel">${name}:</span>${value}`
        }

        function componentLegend(name: string): string {
            console.log('switching', name)
            switch (name) {
                case 'C6H6':
                    return 'Benzene'
                case 'C7H8':
                    return 'Toluene'
                case 'C8H10':
                    return 'Xylene'
                case 'CO':
                    return 'Carbon monoxide'
                case 'FN':
                    return 'Smoke'
                case 'H2S':
                    return 'Hydrogen sulfide'
                case 'NH3':
                    return 'Ammonia'
                case 'NO':
                    return 'Nitrogen monoxide'
                case 'NO2':
                    return 'Nitrogen dioxide'
                case 'O3':
                    return 'Ozone'
                case 'PM10':
                    return 'Particulate matter'
                case 'PM25':
                    return 'Particulate matter'
                case 'PS':
                    return 'Ultra fine particles'
                case 'SO2':
                    return 'Sulphur dioxide'
                default:
                    return ''
            }
        }

        let topic = message.topic
        let ts = topic.split('/')
        let identifier = ts[ts.length - 1]

        let payload = JSON.parse(message.payloadString)
        let ls = payload.location.split('-')
        let location = ls[0]
        let station = ls[1]
        let timestamp = moment(payload.timestamp_measured).format('DD/MM/YYYY HH:mm')
        let measurements = ''
        for (let reading of payload.readings.split(',')) {
            measurements += formatReading(reading)
        }

        let icon = L.divIcon({
            html: iconImage(payload.rating, 24),
            className: 'air-pollution-div-icon',
            iconSize: [24, 24], // size of the icon
            iconAnchor: [12, 12], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -12]  // point from which the popup should open relative to the iconAnchor
        })
        let popupInfo = `<div class="air-pollution-info-window">
            <span class="infolabel"> Station:</span><strong>${station}</strong><br/>
            <span class="infolabel">Location:</span>${location}<br/>
            <span class="infolabel">  Number:</span>${identifier}<br/>
            <span class="infolabel">    Type:</span>${payload.type}<br/>
            <br/>
            <span class="infolabel">    Time:</span>${timestamp}<br/>
            <span class="infolabel">  Rating:</span>${ratingLabel(payload.rating)}<br/>
            ${measurements}
            </div>`

        this.setMarker(this.quadKeyFromTopic(topic, this.regionPrecision), identifier, payload.lat, payload.long, popupInfo, {icon})
    }
}
