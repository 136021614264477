import * as Collections from 'typescript-collections'
import * as L from 'leaflet'
import { MQTTConnector, MQTTMessage } from '../dsh-mqtt'
import { Config } from '../config'
import { InfoLayer } from './info-layer'

export class TrafficInfoLayer extends InfoLayer {
    visible = true;
    metadataDict: Collections.Dictionary<String, Collections.Dictionary<String, LaneMetadata>>
    thresholdsAsValues: Collections.Dictionary<String, number>

    constructor(map: L.Map, client: MQTTConnector, private config: Config, private regionPrecision: number) {
        super(map, [config.mqtt.ndwTopic], [], client)
        this.metadataDict = new Collections.Dictionary<String, Collections.Dictionary<String, LaneMetadata>>()
        this.thresholdsAsValues = new Collections.Dictionary<String, number>()
        this.thresholdsAsValues.setValue('idle', 0)
        this.thresholdsAsValues.setValue('low', 1)
        this.thresholdsAsValues.setValue('medium', 2)
        this.thresholdsAsValues.setValue('high', 3)
        this.thresholdsAsValues.setValue('none', -1)
    }

    onMarkerRemoval(identifier: string): void {
        this.metadataDict.remove(identifier)
    }

    onMessage(message: MQTTMessage): void {
        let topic = message.topic
        let payload = JSON.parse(message.payloadString)

        let splitTopic = topic.split('/')
        let identifier = splitTopic[splitTopic.length - 2]
        let lane = splitTopic[splitTopic.length - 1]

        //update lane metadata
        if (!this.metadataDict.containsKey(identifier)) {
            this.metadataDict.setValue(identifier, new Collections.Dictionary<String, LaneMetadata>())
        }

        let laneDict: Collections.Dictionary<String, LaneMetadata> = this.metadataDict.getValue(identifier)
        if (laneDict.containsKey(lane)) {
            laneDict.getValue(lane).speed = payload.speed
            laneDict.getValue(lane).timestamp = payload.timestamp
            laneDict.getValue(lane).speedThreshold = payload['speed-threshold']
        } else {
            let newLaneMetadata = new LaneMetadata('Lane ' + lane.replace('lane', ''), payload.speed, payload.timestamp, payload['speed-threshold'])
            laneDict.setValue(lane, newLaneMetadata)
        }

        //create/update marker popup text
        let popupInfo = laneDict.values().map((laneMetadata: LaneMetadata) => {
            let speedMarkup = ''
            if (laneMetadata.speedThreshold == 'none') {
                speedMarkup = 'no measurement from'
            } else {
                speedMarkup = `<span class='speed-indicator ndw-speed-${laneMetadata.speedThreshold}'><b>${laneMetadata.speed}</b> km/u</span> on`
            }

            return `<p class='ndw-popup'>
                    ${speedMarkup} <b class='popup-title'>${laneMetadata.label}</b><br/>
                    <b class='popup-text'>${laneMetadata.timestamp}</b>
                </p>`
        }).join('')

        let highestThreshold = this.highestSpeedThresholdOfLanes(laneDict.values())
        let icon = L.icon({
            iconUrl: '/images/ndw-icon-' + highestThreshold + '.png',
            iconSize: [20, 21], // size of the icon
            iconAnchor: [10, 11], // point of the icon which will correspond to marker's location
            popupAnchor: [10, -21] // point from which the popup should open relative to the iconAnchor
        })

        this.setMarker(this.quadKeyFromTopic(topic, this.regionPrecision), identifier, payload.lat, payload.long, popupInfo, {icon})
    }

    highestSpeedThresholdOfLanes(laneMetadataList: LaneMetadata[]): string {
        let highestThreshold = 'none'
        laneMetadataList.forEach((laneMetadata: LaneMetadata) => {
            if (laneMetadata.speedThreshold != null && this.compareSpeedThresholds(laneMetadata.speedThreshold, highestThreshold) > 0) {
                highestThreshold = laneMetadata.speedThreshold
            }
        })

        return highestThreshold
    }

    /**
     * compares two thresholds with each other.
     * @param threshold1
     * @param threshold2
     * @returns {number} negative number when threshold1 is lower then threshold2, positive number when threshold2 is higher then threshold1, and 0 when they are equal.
     */
    compareSpeedThresholds(threshold1, threshold2): number {
        let threshold1AsNumber = this.thresholdsAsValues.getValue(threshold1)
        let threshold2AsNumber = this.thresholdsAsValues.getValue(threshold2)

        return threshold1AsNumber - threshold2AsNumber
    }
}

export class LaneMetadata {
    constructor(
        public label: string,
        public speed: number,
        public timestamp: string,
        public speedThreshold: string
    ){};
}
