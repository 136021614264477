const carIcon = L.icon({
  iconUrl: '/images/5gcar.png',
  iconSize:     [51, 34], // size of the icon
  iconAnchor:   [25, 25], // point of the icon which will correspond to marker's location
  popupAnchor:  [0, -15] // point from which the popup should open relative to the iconAnchor
});

const crosswalkIcon = L.icon({
  iconUrl: `/images/map-crosswalk-light.png`,
  iconSize:     [30, 30],
  iconAnchor:   [15, 15],
  popupAnchor:  [10, -21]
});
const bicycleLightIcon = L.icon({
  iconUrl: `/images/map-bicycle-light.png`,
  iconSize:     [30, 30],
  iconAnchor:   [15, 15],
  popupAnchor:  [10, -21]
});  
const trafficLightIcon = L.icon({
  iconUrl: `/images/map-vehicle-light.png`,
  iconSize:     [30, 30],
  iconAnchor:   [15, 15],
  popupAnchor:  [5, -21]
});

export {
  carIcon,
  crosswalkIcon,
  bicycleLightIcon,
  trafficLightIcon,
}
