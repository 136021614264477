import * as L from 'leaflet'
import { MQTTConnector, MQTTMessage } from '../dsh-mqtt'
import { Config } from '../config'
import * as moment from 'moment'
import { InfoLayer } from './info-layer'

export class SatelliteInfoLayer extends InfoLayer {
    visible = false

    constructor(map: L.Map, client: MQTTConnector, private config: Config, private regionPrecision: number) {
        super(map, [config.mqtt.satelliteTopic], [], client)
    }

    onMarkerRemoval(identifier: string): void {
    }

    tombstone(identifier: string): void {
        if (!this.markers.containsKey(identifier)) {
            return
        }
        let marker = this.markers.getValue(identifier)
        this.markers.remove(identifier)
        this.layer.removeLayer(marker)
        this.onMarkerRemoval(identifier)
    }

    onMessage(message: MQTTMessage): void {
        function iconImage(rating: number, size: number): string {
            let iconstyle = `width:${size}px`
            if (rating > 0) {
                let huerotation = rating * 25 + 35
                iconstyle += `;filter: sepia(100%) hue-rotate(${huerotation}deg) contrast(200%) saturate(3)`
            }
            return `<img style="${iconstyle}" src="/images/satellite.png"/>`
        }


        let topic = message.topic
        let thisQuad = this.quadKeyFromTopic(topic, this.regionPrecision)

        let ts = topic.split('/')
        let identifier = ts[ts.length - 1]

        if (identifier.length > 1 || ts.length > 15)
            return

        let payloadString = JSON.parse(message.payloadString)
        if (!payloadString.hasOwnProperty('satellites') || !payloadString.hasOwnProperty('timestampMeasured'))
            return

        let satellitesData = payloadString.satellites
        let timestamp = moment(payloadString.timestampMeasured, 'YYYYMMDD_HHmmssZ', true)

        let currentTimestamp = moment().add(-10, 'minutes')

        if(!timestamp.isValid() || timestamp < currentTimestamp)
            return

        for (let satellite in satellitesData) {
            let satellitePayload = satellitesData[satellite]
            let satId = satellitePayload.satId

            if (this.markersByQuadKey.containsKey(thisQuad)) {
                let ms = this.markersByQuadKey.getValue(thisQuad)
                if (ms.containsKey(satId)) {
                    console.log(`SatelliteId ${satId} was already registered as marker in this quadKey`)
                    ms.remove(satId)
                    this.tombstone(satId.toString())
                }
            }

            let icon = L.divIcon({
                html: iconImage(satellitePayload, 24),
                className: 'satellite-div-icon',
                iconSize: [24, 24], // size of the icon
                iconAnchor: [12, 12], // point of the icon which will correspond to marker's location
                popupAnchor: [0, -12]  // point from which the popup should open relative to the iconAnchor
            })
            let popupInfo = `<div class="air-pollution-info-window">
                <span class="infolabel"> Name:</span><strong>${satellitePayload.satName}</strong><br/>
                <span class="infolabel"> Id:</span>${satellitePayload.satId}<br/>
                <br/>
                <span class="infolabel">    Time:</span>${timestamp}<br/>
                </div>`

            this.setMarker(thisQuad, satellitePayload.satId, satellitePayload.lat, satellitePayload.long, popupInfo, {icon})
        }

    }
}
