import * as MQTT from 'mqtt'
import * as Collections from 'typescript-collections'
import * as $ from 'jquery'

interface MQTTTokenResponse {
    token: string
}

interface MQTTTokenOptions {
    endpoint: string
    clientId: string
    claims: any[]
    port: number
}

export enum MQTTState {
    Unauthenticated,
    Connecting,
    Connected
}

export interface MQTTMessage {
    payloadString: string
    topic: string
}

export type MQTTOnMessageCallback = (msg: MQTTMessage) => void
export type MQTTOnStateChangeCallback = (state: MQTTState) => void

export interface MQTTOptions {
    tenantApiUrl: string
    onStateChange?: MQTTOnStateChangeCallback
    onMessage?: MQTTOnMessageCallback
}

export class MQTTConnector {
    client: MQTT.Client
    state: MQTTState = MQTTState.Unauthenticated
    connecting: boolean
    connected: boolean

    accessToken: string = ''
    public mqttToken: MQTTTokenOptions;

    authServiceUrl: string

    stateChangeCb: MQTTOnStateChangeCallback
    messageCb: MQTTOnMessageCallback

    subscriptions: Collections.Set<String> = new Collections.Set<String>()

    /* public API */
    constructor(opts: MQTTOptions) {
        this.authServiceUrl = opts.tenantApiUrl
        if (opts.onStateChange) {
            this.stateChangeCb = opts.onStateChange
        } else {
            this.stateChangeCb = () => {}
        }
        if (opts.onMessage) {
            this.messageCb = opts.onMessage
        } else {
            this.messageCb = () => {}
        }
    }

    setAuthentication(accessToken: string): void {
        this.accessToken = accessToken
        this.updateConnectionState()
    }

    subscribe(filter: string) {
        if (this.subscriptions.add(filter) && this.connected) {
            this.client.subscribe(filter)
        }
    }

    unsubscribe(filter: string) {
        if (this.subscriptions.remove(filter) && this.connected) {
            this.client.unsubscribe(filter)
        }
    }

    /* internal functions */
    updateConnectionState(): void {
        let old = this.state
        if (this.accessToken == '' && this.state != MQTTState.Unauthenticated) {
            this.state = MQTTState.Unauthenticated
            this.disconnect()
        } else if (this.accessToken != '') {
            if (!this.connecting && !this.connected) {
                this.connect()
                this.state = MQTTState.Connecting
            } else if (this.connecting) {
                this.state = MQTTState.Connecting
            } else if (this.connected) {
                this.state = MQTTState.Connected
            }
        }
        if (this.state != old) {
            this.stateChangeCb(this.state)
        }

    }

    retryConnect(timeout_ms: number = 2000): void {
        // set the state to Connecting, but back off for timeout_ms before we 
        // actually try to connect
        this.connecting = true
        this.updateConnectionState()
        setTimeout(() => {
            this.connecting = false
            this.updateConnectionState()
        }, timeout_ms)
    }

    connect(): void {
        this.connecting = true
        this.updateConnectionState()

        // console.log(`mqtt connect - fetch MQTT token with access token ${this.accessToken}`)
        $.get({
            url: this.authServiceUrl,
            headers: {
                'Authorization': `Bearer ${this.accessToken}`
            }
        }).done((data: any) => {
            this.doConnect((data as MQTTTokenResponse).token)
        }).fail((jqxhr: any, textStatus: string, error: any) => {
            console.log(`Could not fetch MQTT token: ${textStatus} ${error}`)
            this.retryConnect()
        })
    }

    doConnect(mqttToken: string): void {
        let tokenOpts = this.parseToken(mqttToken)
        this.mqttToken = tokenOpts;
        console.log(`will connect to ${tokenOpts.endpoint}, with client id: ${tokenOpts.clientId}, on port: ${tokenOpts.port}`)
        let connOpts: MQTT.IClientOptions = {
            clientId: tokenOpts.clientId,
            username: "doesntmatter",
            password: mqttToken,
            reconnectPeriod: 0, //no automatic reconnect
            port: tokenOpts.port
        }
        this.client = MQTT.connect(`wss://${tokenOpts.endpoint}:8443/mqtt`, connOpts)
        this.client.on('connect', () => this.onConnect())
        this.client.on('error', (error: Error) => this.onConnectFailure(error))
        this.client.on("message", (topic, message) => this.onMessage(topic, message))
        this.client.on('close', () => this.onDisconnect())
    }

    disconnect(): void {
        if (this.client) {
            try {
                this.client.end()
            } catch (e) {
                console.log(`MQTT client disconnect failed, was it connected in the first place? - ${e}`)
            }
        }
        this.client = null
        this.connected = false
        this.connecting = false
        this.updateConnectionState()
    }

    onConnect(): void {
        this.connected = true
        this.connecting = false
        console.log("MQTT connected")
        this.updateConnectionState()
        // reinstate all subscriptions
        this.subscriptions.forEach((filter: string) => {
            this.client.subscribe(filter)
        })
    }

    onDisconnect(): void {
        console.log(`MQTT client disconnected`)
        this.connected = false
        this.connecting = false
        this.retryConnect()
    }

    onConnectFailure(error: Error): void {
        console.log(`MQTT connect failed - will retry. error: ${error.message}`)
        this.retryConnect()
    }

    onMessage(topic: string, payload: any): void {
        this.messageCb({payloadString: payload.toString(), topic: topic})
    }

    parseToken(token: string): MQTTTokenOptions {
        let opts = {
            endpoint: '',
            clientId: '',
            claims: [],
            port: 8443
        }
        try {
            var ParsedToken = JSON.parse(atob(token.split('.')[1]));
            console.log("MQTT token: ", ParsedToken)
            const claimsDebugList = [];
            ParsedToken.claims.forEach(claim => {
              claimsDebugList.push(`${claim.resource.stream}/${claim.resource.topic}`);
            });
            console.log('claims: ', claimsDebugList);
            var claims = ParsedToken.claims
            var port = ParsedToken.ports.mqttwss[0];
            opts.endpoint = ParsedToken.endpoint || ''
            opts.clientId = ParsedToken['client-id'] || ''
            opts.claims = ParsedToken.claims
            opts.port = parseInt(port)
        } catch (e) {
            console.log(`Could not parse MQTT token - ${e}`)
        }
        return opts
    }
}

export class MqttTopicParser {

    static getStream(topic) {
        let streams = topic.split('/').filter(Boolean)
        // return second topic
        return streams[1]
    }
}

