import * as auth0 from 'auth0-js'

export type AuthenticatorCallback = (isAuthenticated: boolean, token: string) => void
export interface AuthenticatorOptions {
    appid: string
    domain: string
    clientId: string
    audience: string
    scope: string
    onStateChange: AuthenticatorCallback
}

export class Authenticator {
    webAuth: any

    cb: AuthenticatorCallback
    appid: string

    timer: number
    
    constructor(opts: AuthenticatorOptions) {
        this.cb = opts.onStateChange
        this.appid = opts.appid

        let redirectUri = `${window.location.origin}${window.location.pathname}`
        this.webAuth = new auth0.WebAuth({
            domain: opts.domain,
            clientID: opts.clientId,
            redirectUri: redirectUri,
            audience: opts.audience,
            responseType: 'token id_token',
            scope: `openid profile ${opts.scope}`
        })

        this.setExpirationTimer()
    }

    getItem(key: string): string {
        return localStorage.getItem(`${this.appid}.${key}`)
    }

    setItem(key: string, value: string) {
        localStorage.setItem(`${this.appid}.${key}`, value)
    }

    removeItem(key: string) {
        localStorage.removeItem(`${this.appid}.${key}`)
    }

    setExpirationTimer() {
        let expat = this.getItem('expires_at')
        if (expat == null) {
            this.timer = -1
            return
        }

        if (this.timer != -1) {
            clearTimeout(this.timer)
            this.timer = -1
        }

        let cancelIn = (JSON.parse(expat) as number) - (new Date().getTime())
        if (cancelIn > 0) {
            this.timer = setTimeout(() => {
                console.log("access token expired - logging out...")
                this.logout()
            }, cancelIn)
        }
    }

    cancelExpirationTimer() {
        if (this.timer != -1) {
            clearTimeout(this.timer)
            this.timer = -1
        }
    }

    setSession(authResult: any) {
        let expiresAt = JSON.stringify(
            authResult.expiresIn * 1000 + new Date().getTime()
        )
        this.setItem('access_token', authResult.accessToken)
        this.setItem('id_token', authResult.idToken)
        this.setItem('expires_at', expiresAt)
        this.setExpirationTimer()
    }

    login() {
        this.webAuth.authorize()
    }

    logout() {
        // remove auth information from localStorage
        this.removeItem('access_token')
        this.removeItem('id_token')
        this.removeItem('expires_at')
        this.cancelExpirationTimer()
        this.updateState()
    }

    isAuthenticated() {
        let expiresAt = this.getItem('expires_at')
        if (expiresAt == null) {
            return false
        }
        return new Date().getTime() < JSON.parse(expiresAt)
    }

    handleAuthentication() {
        this.webAuth.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                window.location.hash = ''
                this.setSession(authResult)
            } else if (err) {
                console.log(err)
                alert(`Error: ${err}. Check the console for further details.`)
            }
            this.updateState()
        })
    }

    updateState() {
        if (this.isAuthenticated()) {
            this.cb(true, this.getItem('access_token') as string)
        } else {
            this.cb(false, '')
        }
    }
}

