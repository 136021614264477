
import * as L from 'leaflet'
import { Polyline } from 'leaflet'
import { MQTTConnector, MQTTMessage } from '../dsh-mqtt'
import { Config } from '../config'
import { quadKey } from '../util/quadkey'
import { InfoLayer } from './info-layer'

// there are two different kinds of possible payload published on railsense, trains and stations
// we need two separate layers to deal with each of them

// option one: the payload is a station
export class RailInfoLayer extends InfoLayer {
    visible = false

    constructor(map: L.Map, client: MQTTConnector, private config: Config, private regionPrecision: number) {
        super(map, [config.mqtt.railsenseTopic], [], client)
    }

    onMarkerRemoval(identifier: string): void {
        // nothing to be done here
    }

    onMessage(message: MQTTMessage): void {

        // first check that this is a stations message
        let splitTopic: Array<String> = message.topic.split('/')
        let messageType: String = splitTopic[splitTopic.length - 1]

        if (messageType === 'stations') {
            let payload: Array<any> = JSON.parse(message.payloadString)
            let topic = message.topic

            // if the payload has entries, we want to add them to the map
            if (payload.length > 0) {
                for (let station of payload) {
                    let identifier = station.code
                    let popupInfo =
                        `<p class='rail-popup'>
                    <b>Station: </b>${identifier} : ${station.names.lang}<br/>`

                    // this is our default marker--we'll change it if there are interruptions
                    let icon = L.icon({
                        iconUrl: `/images/railstation.png`,
                        iconSize: [35, 35], // size of the icon
                        iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
                        popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
                    })

                    // if there's interruption info, marker becomes red version and we open a table
                    //console.log("delays with size " + station.delays.size + ": " + station.delays)
                    if (station.delays.length > 0) {
                        icon = L.icon({
                            iconUrl: `/images/railstationred.png`,
                            iconSize: [35, 35], // size of the icon
                            iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
                            popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
                        })

                        popupInfo +=
                            `<table class="railsense-delays" border="1px solid #ddd">
                                <caption><b>Interruptions</b></caption>
                                <tr>
                                    <th>Direction</th>
                                    <th>Time</th>
                                    <th>Reason</th>
                                </tr>`

                        // now we check if there's one interruption, or more than one
                        // if it's more than one, we iterate through the array & append info about the interruptions to the table
                        let delays: Object = station.delays

                        Object.keys(delays).forEach(key => {
                            let delay = station.delays[key]
                            let trajectory = delay.disruption.trajectories.find(trajectory =>
                                trajectory.stations.find(station =>
                                    station.toUpperCase() == identifier))
                            popupInfo +=
                                `<tr>
                                        <td>${delay.title}</td>
                                        <td>${new Date(trajectory.start * 1000).toTimeString()}</td>
                                        <td>${delay.disruption.reason}</td>
                                    </tr>`
                        })

                        // now we close the table
                        popupInfo += `</table><br/>`
                    }

                    if (station.departures.length > 0) {
                        popupInfo +=
                            `<table width="100%" border="1px solid #ddd">
                                <caption><b>Departures</b></caption>
                                <tr>
                                    <th>Platform</th>
                                    <th>Destination</th>
                                    <th>Departure</th>
                                    <th>Delay</th>
                                </tr>`

                        // we want either 10 trains or the length of the array, whichever is shorter
                        // this avoids spamming the UI for stations with lots of departures
                        for (let i = 0; i < Math.min(10, station.departures.length); ++i) {
                            let temp = new Date(station.departures[i].plannedDeparture * 1000)
                            popupInfo +=
                                `<tr>
                                    <td>${station.departures[i].platform}</td>
                                    <td>${station.departures[i].destination}</td>
                                    <td>${temp.getHours()}:${temp.getMinutes().toString().length > 1 ? temp.getMinutes() : '0' + temp.getMinutes()}</td>
                                    <td>${station.departures[i].departureDelay ? `<font color="red">` + station.departures[i].departureDelay + `</font>` : 'None'}</td>
                                </tr>`
                        }
                        popupInfo +=
                            `</table>`
                    }
                    var lastUpdated: Date = new Date(Date.now())
                    popupInfo += `<br/><b>Last updated: </b>${lastUpdated.toTimeString()}`
                    this.setMarker(this.quadKeyFromTopic(topic, this.regionPrecision), identifier, station.lat, station.lng, popupInfo, {icon})
                }
            }
        }
    }
}

// option two: the payload is a train
export class TrainInfoLayer extends InfoLayer {
    visible = false
    polyLines: Set<Polyline>

    constructor(private map: L.Map, client: MQTTConnector, private config: Config, private regionPrecision: number) {
        super(map, [config.mqtt.railsenseTopic], [], client)
        this.polyLines = new Set<Polyline>()
    }

    removeAllLines() {
        this.polyLines.forEach(line => {
            line.removeFrom(this.map)
        })
    }

    tombstone(identifier: string): void {
        if (!this.markers.containsKey(identifier)) {
            return
        }
        let marker = this.markers.getValue(identifier)
        this.markers.remove(identifier)
        this.layer.removeLayer(marker)
    }

    onMarkerRemoval(identifier: string): void {
        // nothing to be done here
    }

    onMessage(message: MQTTMessage): void {

        // first check that this is a trains message
        let splitTopic: Array<String> = message.topic.split('/')
        let messageType: String = splitTopic[splitTopic.length - 1]

        if (messageType === 'trains') {
            // remove all old markers
            for (let key in this.markers) {
                this.tombstone(key)
            }

            let payload: Array<any> = JSON.parse(message.payloadString)
            if (payload.length > 0) {
                let topic = message.topic
                let regionQuad = this.quadKeyFromTopic(topic, this.regionPrecision)
                let icon = L.icon({
                    iconUrl: `/images/train.png`,
                    iconSize: [30, 30], // size of the icon
                    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
                    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
                })
                payload.forEach(train => {
                    let identifier: string = train.routeNumber
                    let timeAtNextStation: Date = new Date(train.nextStation.timestamp * 1000)
                    let timeAtNextStationString: String = `${timeAtNextStation.getHours()}:${timeAtNextStation.getMinutes().toString().length > 1 ? timeAtNextStation.getMinutes() : '0' + timeAtNextStation.getMinutes()}`
                    let timeAtPreviousStation: Date = new Date(train.previousStation.timestamp * 1000)
                    let timeAtPreviousStationString: String = `${timeAtPreviousStation.getHours()}:${timeAtPreviousStation.getMinutes().toString().length > 1 ? timeAtPreviousStation.getMinutes() : '0' + timeAtPreviousStation.getMinutes()}`

                    let popupInfo =
                        `<p class='rail-popup'>
                    <b>Train</b>: ${train.provider} ${train.trainType} ${train.routeNumber} to ${train.destination.name}<br/>
                    <b>Next stop</b>: ${train.nextStation.name} at ${timeAtNextStationString}<br/>
                    <b>Previous stop</b>: ${train.previousStation.name} at ${timeAtPreviousStationString}<br/>
                    <b>On time?</b>: ${train.onTime ? 'Yes' : 'No'}<br/>
                    <table width="100%" border="1px solid #ddd">
                        <caption><b>Timetable</b></caption>
                        <tr>
                            <th>Station</th>
                            <th>Time</th>
                        </tr>`

                    let travelPlan: Array<Array<any>> = train.travelPlan

                    // to save space on the screen we only show stops that are coming up, not ones that have already been passed through
                    for (let stopArray of travelPlan) {
                        let now: Date = new Date()
                        let timeAtStop: Date = new Date(stopArray[0] * 1000)
                        if (now < timeAtStop) {
                            let stop = stopArray[1]
                            let timeStringAtStop: String = `${timeAtStop.getHours()}:${timeAtStop.getMinutes().toString().length > 1 ? timeAtStop.getMinutes() : '0' + timeAtStop.getMinutes()}`
                            popupInfo +=
                                `<tr>
                                <td>${stop.name}</td>
                                <td>${timeStringAtStop}</td>
                            </tr>`
                        }
                    }

                    popupInfo += `</table></p>`
                    this.setMarker(regionQuad, identifier, train.lat, train.lng, popupInfo, {icon})

                    // now we can add a polyline between the two stations, for funsies
                    // we get some coordinates for the new polyline
                    let latlngs = [
                        [train.previousStation.lat, train.previousStation.lng],
                        [train.nextStation.lat, train.nextStation.lng]
                    ]
                    let polyline = L.polyline(latlngs, {color: 'red'})
                    this.polyLines.add(polyline)
                    this.polyLines.forEach(line => {
                        let quadStartLine = quadKey.latLongToQuadKey(line.getLatLngs()[0].lat, line.getLatLngs()[0].lng, 12)
                        let quadEndLine = quadKey.latLongToQuadKey(line.getLatLngs()[1].lat, line.getLatLngs()[1].lng, 12)
                        let quadTrain = quadKey.latLongToQuadKey(train.lat, train.lng, 12)
                        if ((this.region.contains(quadStartLine.toString()) ||
                            this.region.contains(quadEndLine.toString())) &&
                            this.region.contains(quadTrain)) {
                            line.addTo(this.map)
                        } else {
                            line.removeFrom(this.map)
                            this.polyLines.delete(line)
                        }
                    })
                });
            }
        }
    }
}
