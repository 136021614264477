// utilities for parsing SPDP data
//
import * as Collections from 'typescript-collections'

interface SPDPTimeOfDay {
    h: number | string
    m: number | string
    s: number | string
}

function isCurrentlyValid(entry: any, fromField: string = 'validityStartOfPeriod', toField: string = 'validityEndOfPeriod'): boolean {
    let now = Date.now() / 1000
    let start = entry[fromField] || 1
    let end = entry[toField] || Number.MAX_SAFE_INTEGER
    return start <= now && end > now
}

function currentlyValidEntry(arr: Array<any>, fromField: string = 'validityStartOfPeriod', toField: string = 'validityEndOfPeriod'): any {
    let valid = currentlyValidEntries(arr, fromField, toField)
    return valid.length > 0 ? valid[0] : null
}

function currentlyValidEntries(arr: Array<any>, fromField: string = 'validityStartOfPeriod', toField: string = 'validityEndOfPeriod'): Array<any> {
    return (arr || []).filter(e => e && isCurrentlyValid(e, fromField, toField))
}

function forceInt(n: number|string): number {
    return typeof n == "string" ? parseInt(n) : n
}

function forceFloat(n: number|string): number {
    return typeof n == "string" ? parseFloat(n) : n
}

function forceMeters(n: number) {
    return n > 100 ? n / 100 : n
}

function forceSPDPTimeOfDay(tod: any): SPDPTimeOfDay|null {
    if (!tod) return null
    let h = tod.h || tod.H || tod.hour || tod.Hour || tod.hours || tod.Hours || 0
    let m = tod.m || tod.M || tod.minute || tod.Minute || tod.minutes || tod.Minutes || 0
    let s = tod.s || tod.S || tod.second || tod.Second || tod.seconds || tod.Seconds || 0
    return { h: h, m: m, s: s }
}

let daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export class ParkingTimeRange {
    fromH: number
    fromM: number
    toH: number
    toM: number

    constructor(fromT?: any, toT?: any) {
        // some SPDP data uses stringified numbers... sigh...
        let fromTime = forceSPDPTimeOfDay(fromT)
        let toTime = forceSPDPTimeOfDay(toT)

        if (!fromTime) {
            this.fromH = 0
            this.fromM = 0
        } else {
            this.fromH = forceInt(fromTime.h)
            this.fromM = forceInt(fromTime.m)
        }
        if (!toTime) {
            this.toH = 23
            this.toM = 59
        } else {
            this.toH = forceInt(toTime.h)
            this.toM = forceInt(toTime.m)
        }
    }

    toHTML(): string {
        let fmt = (n: number) => `${n < 10 ? '0' : ''}${n.toFixed(0)}`
        return `${fmt(this.fromH)}:${fmt(this.fromM)} &ndash; ${fmt(this.toH)}:${fmt(this.toM)}`
    }
}

export class ParkingIntervalRange {
    unit: string
    charge: number
    chargePeriod: number

    constructor(interval: any) {
        this.charge = forceFloat(interval.charge)
        this.chargePeriod = forceInt(interval.chargePeriod)
        this.unit = interval.durationType
    }

    toHTML(): string {
        return `&euro;${this.charge.toFixed(2)} per ${this.chargePeriod} ${this.unit}`
    }
}

export class ParkingTariff {
    days: Array<String>
    maximumDayCharge: number
    validHours: ParkingTimeRange
    intervals: Array<ParkingIntervalRange>

    constructor(tariff: any) {
        this.maximumDayCharge = forceFloat(tariff.maximumDayCharge || -1)
        this.days = tariff.validityDays || daysOfWeek
        this.validHours = new ParkingTimeRange(tariff.validityFromTime, tariff.validityUntilTime)
        this.intervals = (tariff.intervalRates || []).map(interval => new ParkingIntervalRange(interval))
    }
}

let static_topic_re = RegExp("^(.*)\/spdp-stat_([^/]+)\/.*\/([0-9a-zA-Z-]+)$")
let dynamic_topic_re = RegExp("^(.*)\/spdp-dyn_([^/]+)\/([0-9a-zA-Z-]+)$")

export class ParkingData {
    quad: string // will be filled in by the upper layers that know about geo quadtrees
    id: string // a truly unique identifier, robust against the parking data appearing on multiple topics (e.g. _v1 and _v2 or _vp and _tp)
    identifier: string // the parking identifier from the SPDP data
    dynamicDataTopic: string
    lastUpdate: number
    address: string
    lat: number
    lng: number
    name: string
    capacity: number
    vacantSpaces: number
    open: boolean
    full: boolean
    minHeight: number // height of the lowest ceiling
    usage: string // what kind of parking is it (park & ride, on-street, garage, ...)
    openingTimes: Collections.Dictionary<String, ParkingTimeRange>
    entrances: Array<string>
    tariffs: Array<ParkingTariff>

    // specific to bicycle parkings?
    mopeds: boolean
    motorBikes: boolean
    eBikes: boolean
    guarded: boolean
    reparations: boolean
    rental: boolean
    hasPump: boolean

    constructor() {
        this.openingTimes = new Collections.Dictionary<String, ParkingTimeRange>()
        this.entrances = new Array<string>()
        this.tariffs = new Array<ParkingTariff>()
    }

    static dynamicTopicFromStaticTopic(stopic: string): string {
        return stopic.replace(static_topic_re, "$1/spdp-dyn_$2/$3")
    }

    static idFromTopic(topic: string): string {
        if (static_topic_re.test(topic)) {
            return topic.replace(static_topic_re, "$3-$2").toLowerCase()
        } else if (dynamic_topic_re.test(topic)) {
            return topic.replace(dynamic_topic_re, "$3-$2").toLowerCase()
        }
        console.error(`unrecognized topic pattern: ${topic}`)
        return topic
    }
    
    parseStaticDataV2(payload: string, topic: string): boolean {
        // parsing is messy because the data is pretty bad (capitalization errors, schema deviations, ...)
        try {
            let parsed = JSON.parse(payload)
            let pfi = parsed.parkingFacilityInformation || parsed.ParkingFacilityInformation
            this.id = ParkingData.idFromTopic(topic)
            this.identifier = pfi.identifier
            this.lastUpdate = 0
            this.dynamicDataTopic = ParkingData.dynamicTopicFromStaticTopic(topic)

            if (pfi.locationForDisplay) {
                this.lat = pfi.locationForDisplay.latitude
                this.lng = pfi.locationForDisplay.longitude
            } else if (pfi.accessPoints && pfi.accessPoints.length > 0
                && pfi.accessPoints[0].accessPointLocation && pfi.accessPoints[0].accessPointLocation.length > 0
                && pfi.accessPoints[0].accessPointLocation[0].latitude
                && pfi.accessPoints[0].accessPointLocation[0].longitude) {
                this.lat = pfi.accessPoints[0].accessPointLocation[0].latitude
                this.lng = pfi.accessPoints[0].accessPointLocation[0].longitude
            } else {
                throw new Error("no coordinates for the parking garage found")
            }

            this.name = pfi.name
            let spec = currentlyValidEntry(pfi.specifications)
            if (spec) {
                this.capacity = spec.capacity || -1
                this.minHeight = forceMeters(spec.minimumHeightInMeters || -1)
                this.usage = spec.usage || "unknown"
            } else {
                this.capacity = -1
                this.minHeight = -1
                this.usage = "unknown"
            }
            this.vacantSpaces = -1

            // opening times - this is a mess
            let openingHours = currentlyValidEntry(pfi.openingTimes, 'startOfPeriod', 'endOfPeriod') || {}
            this.openingTimes.clear()
            for (let entryTime of (openingHours.entryTimes || [])) {
                let oh = new ParkingTimeRange(entryTime.enterFrom as SPDPTimeOfDay, entryTime.enterUntil as SPDPTimeOfDay)
                for (let day of entryTime.dayNames) {
                    this.openingTimes.setValue(day, oh)
                }
            }
            // no opening times specified is taken to mean 24/7 opening
            if (this.openingTimes.keys().length == 0) {
                for (let day of daysOfWeek) {
                    this.openingTimes.setValue(day, new ParkingTimeRange())
                }
            }

            // vehicle entrance locations
            let aps = pfi.accessPoints || []
            this.entrances = aps.filter(ap => ap && ap.isVehicleEntrance && ap.accessPointAddress)
                .map(ap => ap.accessPointAddress)
                .map(addr => `${addr.streetName} ${addr.houseNumber ? " "+addr.houseNumber : ""}, ${addr.city}`)

            // parking tariffs
            this.tariffs = currentlyValidEntries(pfi.tariffs || [], 'startOfPeriod', 'endOfPeriod')
                .map(t => new ParkingTariff(t))

            // services are really only relevant for bicycle parkings
            let services = pfi.services || {}
            this.mopeds = !!services.mopeds
            this.guarded = !!services.guarded
            this.hasPump = !!services.hasPump
            this.reparations = !!services.reparations
            this.rental = !!services.rental
            this.motorBikes = !!services.motorBikes
            this.eBikes = !!services.ebikes

            return true
        } catch (e) {
            console.error(`could not parse data - error was ${e} - stack trace is ${e.stack}`)
            return false
        }
    }

    parseStaticDataV1(payload: string, topic: string): boolean {
        try {
            let parsed = JSON.parse(payload)
            let pfi = parsed.parkingFacilityStaticInformation || parsed.ParkingFacilityStaticInformation
            this.id = ParkingData.idFromTopic(topic)
            this.lastUpdate = 0
            this.dynamicDataTopic = ParkingData.dynamicTopicFromStaticTopic(topic)
            this.lat = pfi.locationForDisplay.latitude
            this.lng = pfi.locationForDisplay.longitude
            this.name = pfi.name

            let spec = pfi.specification
            if (spec) {
                this.capacity = spec.capacity || -1
                this.minHeight = forceMeters(spec.minimumHeightInMeters || spec.minimumHeigtInMeters || -1) // yes indeed, the Heigt typo appears in the wild... sigh...
                this.usage = spec.usage || "unknown"
            } else {
                this.capacity = -1
                this.minHeight = -1
                this.usage = "unknown"
            }
            this.vacantSpaces = -1

            // don't bother with any of the extra information - the few SPDPv1 samples we have never include it.
            return true
        } catch (e) {
            console.error(`Failed to parse ${payload} - error was ${e} - stack trace is ${e.stack}`)
            return false
        }
    }

    parseDynamicDataV2(payload: string) {
        try {
            let parsed = JSON.parse(payload)
            let pfdi = parsed.parkingFacilityDynamicInformation || parsed.ParkingFacilityDynamicInformation
            let actual = pfdi.facilityActualStatus
            this.lastUpdate = actual.lastUpdated
            this.open = actual.open
            this.full = actual.full
            this.capacity = actual.parkingCapacity || this.capacity
            this.vacantSpaces = (typeof actual.vacantSpaces == "number") ? actual.vacantSpaces : -1
        } catch (e) {
            console.error(`Failed to parse ${payload} - error was ${e} - stack trace is ${e.stack}`)
        }
    }

    parseDynamicDataV1(payload: string) {
        // v1 and v2 dynamic data are the same according to the spec
        this.parseDynamicDataV2(payload)
    }
}

