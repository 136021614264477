import * as L from 'leaflet'
import { MQTTConnector, MQTTMessage } from '../dsh-mqtt'
import { Config } from '../config'
import * as $ from 'jquery'
import { InfoLayer } from './info-layer'

/**
 * Layers which visualizes bus, tram and metro datapoints.
 */
export class Kv6InfoLayer extends InfoLayer {
    visible = false

    constructor(map: L.Map, client: MQTTConnector, private config: Config, private regionPrecision: number) {
        super(map, [config.mqtt.kv6Topic], [], client)
    }

    onMarkerRemoval(identifier: string): void {
    }

    tombstone(identifier: string): void {
        if (!this.markers.containsKey(identifier)) {
            return
        }
        let marker = this.markers.getValue(identifier)
        this.markers.remove(identifier)
        this.layer.removeLayer(marker)
        this.onMarkerRemoval(identifier)
    }

    onMessage(message: MQTTMessage): void {
        let topic = message.topic
        let identifier = topic.split('/')

        if (message.payloadString == '') {
            return
        }

        let bussesData = JSON.parse(message.payloadString)
        let thisQuad = this.quadKeyFromTopic(topic, this.regionPrecision)

        for (let bus in bussesData) {
            let payload = bussesData[bus]

            if (payload.position == null) { // position is optional (is it?)
                continue
            }
            let key = payload.key
            const markerIdentifier = `${key.dataOwnercode}${key.linePlanningNumber}`;
            const busSize = 15;
            let icon = L.divIcon({
                iconSize: [busSize, busSize], // size of the icon
                iconAnchor: [busSize / 2, busSize / 2], // point of the icon which will correspond to marker's location
                popupAnchor: [busSize / 2, -busSize / 2], // point from which the popup should open relative to the iconAnchor
                className: `my-awesome-bus ${markerIdentifier}`
            });
            $(`.${markerIdentifier}`).css({
                'background-size': busSize,
                'width': busSize
            });

            let punc = 'n/a'
            switch (true) {
                case payload.punctuality == null:
                    break;
                case payload.punctuality > 0:
                    punc = `${payload.punctuality}s late`
                    break;
                case payload.punctuality < 0:
                    punc = `${-payload.punctuality}s early`
                    break;
                case payload.punctuality == 0:
                    punc = `on time`
                    break;
            }

            let popupInfo = `
              <p class='ndw-popup'>
                ${key.dataOwnercode}   ${key.linePlanningNumber}
              </p>
              <p class='ndw-popup'>${identifier}</p>
              <p class='ndw-popup'>updated: ${payload.updatedAt}</p>
              <p class='ndw-popup'>punctuality: ${punc}</p>
              <p class='ndw-popup'>state: ${payload.state}</p>`

            this.setMarker(
                thisQuad,
                bus,
                payload.position.lat, payload.position.lng,
                popupInfo,
                {icon}
            );
        }

        // The mqtt blobs contain exactly all vehicles in a quad key. When a vehicle is removed,
        // the blob simply doesn't contain it anymore. To get rid of markers, lookup all markers
        // and see which exist in the blob. The markers that don't are removed.
        if (this.markersByQuadKey.containsKey(thisQuad)) {
            let ms = this.markersByQuadKey.getValue(thisQuad)
            for (let mk of ms.keys()) {
                if (!bussesData.hasOwnProperty(mk.toString())) {
                    ms.remove(mk)
                    this.tombstone(mk.toString())
                }
            }
        }
    }
}
