/**
 * Node Module to work with QuadKeys.
 * A javascript port based on the C# code provided here: https://msdn.microsoft.com/en-us/library/bb259689.aspx
 */

class QuadKey {

    quadkey = {
        MinLatitude: -85.05112878,
        MaxLatitude: 85.05112878,
        MinLongitude: -180,
        MaxLongitude: 180,
        TileSize: 256 //256 x 256
    };

    /**
     * Converts latitude/longitude coordinates to a QuadKey with the given level of detail.
     * @param lat latitude coordinate
     * @param lng longitude coordinate
     * @param levelOfDetail the level of detail used in quadkeys, going from 1 to 23.
     */
    latLongToQuadKey(lat, lng, levelOfDetail) {
        let pixelXY = this.latLongToPixelXY(lat, lng, levelOfDetail);
        let tileXY = this.pixelXYToTileXY(pixelXY.x, pixelXY.y);
        return this.tileXYToQuadKey(tileXY.x, tileXY.y, levelOfDetail);
    };


    /**
     * Converts a QuadKey to latitude/longitude coordinates.
     * @param quadKey the quadkey to convert
     * @returns {{lat: number, lng: number}}
     */
    quadKeyToLatLong(quadKey) {
        let tileXY = this.quadKeyToTileXY(quadKey);
        let pixelXY = this.tileXYToPixelXY(tileXY.x, tileXY.y);
        return this.pixelXYToLatLong(pixelXY.x, pixelXY.y, quadKey.length);
    };

    /**
     * Converts a Tile XY coordinates to a QuadKey.
     * @param x the tile's coordinate on the x-axis
     * @param y the tile's coordinate on the y-axis
     * @param levelOfDetail the level of detail, going from 1 to 23.
     * @returns {string} the quad key
     */
    tileXYToQuadKey(x, y, levelOfDetail) {
        let quadKey = "";

        for (let i = levelOfDetail; i > 0; i--) {
            let digit = 0;
            let mask = 1 << (i - 1);
            if ((x & mask) !== 0) {
                digit++;
            }
            if ((y & mask) !== 0) {
                digit += 2;
            }
            quadKey += digit;
        }

        return quadKey;
    };

    /**
     * Converts a QuadKey into tile XY coordinates.
     * @param quadKey the quadKey
     */
    quadKeyToTileXY(quadKey) {
        let tileX = 0;
        let tileY = 0;
        let levelOfDetail = quadKey.length;

        for (let i = levelOfDetail; i > 0; i--) {
            let mask = 1 << (i - 1);
            switch (quadKey.charAt(levelOfDetail - i)) {
                case '0':
                    break;

                case '1':
                    tileX |= mask;
                    break;

                case '2':
                    tileY |= mask;
                    break;

                case '3':
                    tileX |= mask;
                    tileY |= mask;
                    break;

                default:
                    throw "Invalid QuadKey digit sequence.";
            }
        }

        return {
            x: tileX,
            y: tileY
        };
    };

    /**
     * Determines the neighbouring quadKey based on the provided north/east values.
     * @param quadKey the quadKey from which to determine the neighbour.
     * @param north can have the following values: 1=go north, 0=no direction, -1=go south
     * @param east can have the following values: 1=go east, 0=no direction, -1=go west
     */
    neighbour(quadKey, north, east) {
        let tileXY = this.quadKeyToTileXY(quadKey);
        let neighbourTileXY = {
            x: tileXY.x + north,
            y: tileXY.y + east
        }

        return this.tileXYToQuadKey(neighbourTileXY.x, neighbourTileXY.y, quadKey.length);
    };

    /**
     * Determines the bounding box of the quadkey, provided as upper-left and lower-right lat/long coordinates.
     * @param quadKey the quadkey to determine the bounding box from.
     * @returns {{minlng: number, minlat: number, maxlng: number, maxlat: number}}
     */
    bbox(quadKey) {
        let tileXY = this.quadKeyToTileXY(quadKey);
        let upperLeftPixelX = tileXY.x * this.quadkey.TileSize;
        let upperLeftPixelY = tileXY.y * this.quadkey.TileSize;
        let lowerRightPixelX = upperLeftPixelX + this.quadkey.TileSize;
        let lowerRightPixelY = upperLeftPixelY + this.quadkey.TileSize;

        let upperLeftLatLong = this.pixelXYToLatLong(upperLeftPixelX, upperLeftPixelY, quadKey.length);
        let lowerRightLatLong = this.pixelXYToLatLong(lowerRightPixelX, lowerRightPixelY, quadKey.length);
        return {
            minlng: upperLeftLatLong.lng,
            minlat: upperLeftLatLong.lat,
            maxlng: lowerRightLatLong.lng,
            maxlat: lowerRightLatLong.lat
        };
    };

    /**
     * Converts latitude/longitude coordinates to pixel-based x/y coordinates.
     * @param latitude
     * @param longitude
     * @param levelOfDetail the level of detail, going from 1 to 23.
     * @returns {{x: number, y: number}}
     */
    latLongToPixelXY(latitude, longitude, levelOfDetail) {
        let clipLatitude = this.clip(latitude, this.quadkey.MinLatitude, this.quadkey.MaxLatitude);
        let clipLongitude = this.clip(longitude, this.quadkey.MinLongitude, this.quadkey.MaxLongitude);

        let x = (clipLongitude + 180) / 360;
        let sinLatitude = Math.sin(clipLatitude * Math.PI / 180);
        let y = 0.5 - Math.log((1 + sinLatitude) / (1 - sinLatitude)) / (4 * Math.PI);

        let mapSize = this.mapSize(levelOfDetail);
        let pixelX = this.clip(x * mapSize + 0.5, 0, mapSize - 1);
        let pixelY = this.clip(y * mapSize + 0.5, 0, mapSize - 1);

        return {
            x: pixelX,
            y: pixelY
        };
    };

    /**
     * Converts a pixel from pixel XY coordinates at a specified level of detail
     * into latitude/longitude WGS-84 coordinates (in degrees).
     * @param pixelX X coordinate of the point, in pixels
     * @param pixelY Y coordinates of the point, in pixels
     * @param levelOfDetail level of detail, from 1 (lowest detail) to 23 (highest detail).
     * @returns {{lat: number, lng: number}}
     */
    pixelXYToLatLong(pixelX, pixelY, levelOfDetail) {
        let mapSize = this.mapSize(levelOfDetail);
        let x = (this.clip(pixelX, 0, mapSize - 1) / mapSize) - 0.5;
        let y = 0.5 - (this.clip(pixelY, 0, mapSize - 1) / mapSize);

        let latitude = 90 - 360 * Math.atan(Math.exp(-y * 2 * Math.PI)) / Math.PI;
        let longitude = 360 * x;

        return {
            lat: latitude,
            lng: longitude
        };
    };

    /**
     * Converts pixel XY coordinates into tile XY coordinates of the tile containing the specified pixel.
     * @param pixelX
     * @param pixelY
     * @returns {{x: number, y: number}}
     */
    pixelXYToTileXY(pixelX, pixelY) {
        return {
            x: pixelX / 256,
            y: pixelY / 256
        };
    };

    /**
     * Converts tile XY coordinates into pixel XY coordinates of the upper-left pixel of the specified tile.
     * @param pixelX
     * @param pixelY
     * @returns {{x: number, y: number}}
     */
    tileXYToPixelXY(tileX, tileY) {
        return {
            x: tileX * 256,
            y: tileY * 256
        };
    };

    /**
     * Clips a number to the specified minimum and maximum values.
     * @param number the number to clip
     * @param minValue minimum allowable value
     * @param maxValue maximum allowable value
     * @returns {number} clipped number
     */
    clip(number, minValue, maxValue) {
        return Math.min(Math.max(number, minValue), maxValue);
    };

    /**
     * Determines the map width and height (in pixels) at the specified levelOfDetail.
     * @param levelOfDetail level of detail, starting from 1 (lowest detail) to 23 (highest detail)
     * @returns {number} the map width and height in pixels
     */
    mapSize(levelOfDetail) {
        return 256 << levelOfDetail;
    };

}

export let quadKey = new QuadKey();