import * as L from 'leaflet'
import { MQTTConnector, MQTTMessage } from '../dsh-mqtt'
import { Config } from '../config'
import { quadKey } from '../util/quadkey'
import { InfoLayer } from './info-layer'

export class WeatherInfoLayer extends InfoLayer {
    visible = false

    constructor(map: L.Map, client: MQTTConnector, private config: Config, private regionPrecision: number) {
        super(map, [config.mqtt.weatherTopic], [], client)
    }

    onMarkerRemoval(identifier: string): void {
        // nothing to be done here
    }

    onMessage(message: MQTTMessage): void {
        let topic = message.topic
        let payload = JSON.parse(message.payloadString)

        let regionQuad = this.quadKeyFromTopic(topic, this.regionPrecision)
        let positionQuad = regionQuad + '1111'; // this is the far upper right corner of the region
        let coords = quadKey.quadKeyToLatLong(positionQuad)
        let identifier = positionQuad

        let isSunUp = ((Date.now() / 1000) >= payload.sunrise) && ((Date.now() / 1000) < payload.sunset)
        let timestamp = new Date(payload.timestamp * 1000)

        let popupInfo = `<p class='weather-popup'>
                        <b>${payload.description}</b><br/>
                        <b>Temperature</b> ${(payload.temperature - 273.15).toFixed(2)}C<br/>
                        <b>Cloud cover</b> ${payload.cloudcover}%<br/>
                        <b>Wind speed</b> ${payload.windspeed}km/h<br/>
                        <b>Rain in last 3 hours</b> ${payload.rain_3h}<br/>
                        <b>Snow in last 3 hours</b> ${payload.snow_3h}<br/>
                        <b>Sun up</b> ${isSunUp ? 'yes' : 'no'}<br/>
                        <b class='popup-text'>${timestamp.toString()}</b>
                        </p>`

        let icon = L.icon({
            iconUrl: payload.icon,
            iconSize: [40, 42], // size of the icon
            iconAnchor: [20, 21], // point of the icon which will correspond to marker's location
            popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
        })

        this.setMarker(regionQuad, identifier, coords.lat, coords.lng, popupInfo, {icon, zIndexOffset: 1000})
    }
}
