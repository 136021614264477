import * as L from 'leaflet'
import * as Collections from 'typescript-collections'
import { ParkingData, ParkingTimeRange } from '../spdp'
import { MQTTConnector, MQTTMessage } from '../dsh-mqtt'
import { Config } from '../config'
import { InfoLayer } from './info-layer'

export class BicycleParkingInfoLayer extends InfoLayer {
    visible = false
    parkings: Collections.Dictionary<String, ParkingData>

    constructor(map: L.Map, client: MQTTConnector, private config: Config, private regionPrecision: number) {
        super(map, [config.mqtt.bicycleParkingTopic], [], client)
        this.parkings = new Collections.Dictionary<String, ParkingData>()
    }

    onMarkerRemoval(identifier: string): void {
        this.parkings.remove(identifier)
    }

    updateParkingData(message: MQTTMessage): ParkingData {
        let id = ParkingData.idFromTopic(message.topic)
        let pdata: ParkingData = this.parkings.getValue(id)
        if (pdata) {
            pdata.parseStaticDataV2(message.payloadString, message.topic)
        } else {
            pdata = new ParkingData()
            if (!pdata.parseStaticDataV2(message.payloadString, message.topic)) return null
            this.parkings.setValue(id, pdata)
            pdata.quad = this.quadKeyFromTopic(message.topic, this.regionPrecision)
        }
        return pdata
    }

    onMessage(message: MQTTMessage): void {
        let topic = message.topic
        let pdata = this.updateParkingData(message)
        if (!pdata) {
            console.error(`could not decode incoming message: ${topic} - ${message.payloadString}`);
            return
        }

        let identifier = pdata.id

        let popupInfo = `<div class='bicycle-parking-popup' style="max-height: 300px; overflow: auto;">
                        <b>${pdata.name}</b><br/>`
        if (pdata.capacity != -1) {
            popupInfo += `<b>Capacity</b> ${pdata.capacity}<br/>`
        }
        if (pdata.address) {
            popupInfo += `<b>Address</b> ${pdata.address}<br/>`
        }
        if (pdata.entrances.length > 0) {
            popupInfo += `<b>Entrances</b><br/>`
            pdata.entrances.map(e => {
                popupInfo += `&nbsp;${e}<br/>`
            })
        }
        popupInfo += `
            <b>E-Bikes</b> ${pdata.eBikes ? 'yes' : 'no'}<br/>
            <b>Mopeds</b> ${pdata.mopeds ? 'yes' : 'no'}<br/>
            <b>Motorbikes</b> ${pdata.motorBikes ? 'yes' : 'no'}<br/>
            <b>Fuel Pump</b> ${pdata.hasPump ? 'yes' : 'no'}<br/>
            <b>Guarded</b> ${pdata.guarded ? 'yes' : 'no'}<br/>
            <b>Reparations</b> ${pdata.reparations ? 'yes' : 'no'}<br/>
            <b>Rental</b> ${pdata.rental ? 'yes' : 'no'}<br/>
            `
        popupInfo += `<b>Opening times</b><br/>`
        pdata.openingTimes.forEach((k: string, v: ParkingTimeRange) => {
            popupInfo += `&nbsp;${k}: ${v.toHTML()}<br/>`
        })
        if (pdata.tariffs.length > 0) {
            popupInfo += `<b>Tariffs</b><br/>`
            for (let tariff of pdata.tariffs) {
                popupInfo += `&nbsp;on ${tariff.days.join(', ')} (${tariff.validHours.toHTML()}):<br/>`
                for (let interval of tariff.intervals) {
                    popupInfo += `&nbsp;&nbsp;&nbsp;${interval.toHTML()}<br/>`
                }
                if (tariff.maximumDayCharge != -1) {
                    popupInfo += `&nbsp;&nbsp;&nbsp;&nbsp;maximum day charge is &euro;${tariff.maximumDayCharge}<br/>`
                }
            }
        }

        let icon = L.icon({
            iconUrl: `/images/bicycle-parking.png`,
            iconSize: [24, 30], // size of the icon
            iconAnchor: [12, 15], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -15] // point from which the popup should open relative to the iconAnchor
        })

        this.setMarker(pdata.quad, identifier, pdata.lat, pdata.lng, popupInfo, {icon})
    }
}

