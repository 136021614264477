import * as $ from 'jquery'

import { InfoLayer, TalkingTrafficMarker } from '../info-layer';
import { MQTTConnector, MQTTMessage } from '../../dsh-mqtt';
import { Config } from '../../config';
import { bicycleLightIcon, carIcon, crosswalkIcon as crosswalkLightIcon, trafficLightIcon } from './5G-icons';
import { LIVE } from '../layer-toggle';
import * as L from 'leaflet'
import * as Collections from 'typescript-collections'
import { Try } from 'monapt'

const MAPSTREAM =  'map-';
const SPATSTREAM =  'spat-';
const COORDDIVIDER = 10000000;

export type MarkerType =
    'cam' |
    'map-vehicle' |
    'map-bikeLane' |
    'map-crosswalk' |
    'spat-green' |
    'spat-yellow' |
    'spat-red';

export class Generic5GInfoLayer extends InfoLayer {
  visible = false
  isLiveLayer;

  constructor(
      map: L.Map,
      client: MQTTConnector,
      topics: string[],
      liveLayer: boolean
  ) {
    super(map, topics, [], client);
    this.isLiveLayer = liveLayer
  }

  onMarkerRemoval(identifier: string): void {}

  onMessage(message: MQTTMessage): void {}
}

export class CAM5GInfoLayer extends Generic5GInfoLayer {

  visible = false;
  private identifiersWithLastMessageTimestamp: Collections.Dictionary<string, number>;

  constructor(
    map: L.Map,
    client: MQTTConnector,
    private config: Config,
    private topics: string[],
    private regionPrecision: number,
    isLiveLayer: boolean
  ) {
    super(map, client, topics, isLiveLayer);
    this.identifiersWithLastMessageTimestamp = new Collections.Dictionary<string, number>();

    setInterval(() => this.markerGarbageCollect(), 2500);
  }

  onMarkerRemoval(identifier: string): void {}

  onMessage(message: MQTTMessage): void {
    let topic = message.topic
    let payload = JSON.parse(message.payloadString)
    let identifier = payload.header.stationID

    const highFreqContainer = payload.cam.camParameters.highFrequencyContainer[1];
    const angle = highFreqContainer.heading.headingValue / 10; // tenths of a degree
    const speed = (highFreqContainer.speed.speedValue * 0.036).toFixed(2); // cm/s
    const yawrate = highFreqContainer.yawRate.yawRateValue === 32767 ?
      'unavailable' : highFreqContainer.yawRate.yawRateValue / 100 ;
    const length = highFreqContainer.vehicleLength.vehicleLengthValue / 10;
    const width = highFreqContainer.vehicleWidth / 10;
    const direction = yawrate > 0 ? 'right' : 'left';
    const acceleration = highFreqContainer.longitudinalAcceleration.longitudinalAccelerationValue / 10

    const yawrateHtml = yawrate === 'unavailable' ?
      '' : `<b class='popup-text'>Turning: ${yawrate}&#176;/sec ${direction}</b><br/>`;

    const splitTopic = topic.split('/');
    const camQuadkey = splitTopic
      .slice(3, splitTopic.length-1) // select quadkey from topic
      .slice(0, 12) // get quadkey lvl12 instead of 20
      .join('') // stringify
    const camQuadkeyParam = `?quadkey=${camQuadkey}`;
    const url = `${this.config.KPN_5G_3D_UI}${camQuadkeyParam}&type=car&id=${identifier}&live=${LIVE}`
    let popupInfo =
    `<p class='ndw-popup'>
        <b class='popup-title'>ID: ${identifier}</b><br/><br/>
        <b class='popup-text'>Speed: ${speed} km/h</b><br/>
        <b class='popup-text'>Acceleration: ${acceleration} m/s<sup>2</sup></b><br/>
        ${yawrateHtml}
        <b class='popup-text'>Width: ${width}m, length: ${length}m</b><br/>
        <h4>
          <a href="${url}" target="_blank" class="viewLink">Detail view</a>
        </h4>
      </p>`

    const popupContainer = $('<div />');
    popupContainer.on('mousedown', () => this.openInNewTab(url));
    popupContainer.html(popupInfo);

    const pos = payload.cam.camParameters.basicContainer.referencePosition
    const lat = pos.latitude / COORDDIVIDER;
    const long = pos.longitude / COORDDIVIDER;
    const key = this.quadKeyFromTopic(topic, this.regionPrecision);
    const marker = { icon: carIcon, angle }
    this.setMarker(key, identifier, lat, long, popupContainer[0], marker)

    // track the time since a message for an identifier has last been seen, so that we can clean it up when no message has been received in a while
    this.identifiersWithLastMessageTimestamp.setValue(identifier, (new Date).getTime())
  }

  openInNewTab(href) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href,
    }).click();
  }

  /**
   * Cleans up markers which aren't needed anymore.
   */
  private markerGarbageCollect(): void {
    this.identifiersWithLastMessageTimestamp.keys().forEach((identifier) => {
      const lastSeen = this.identifiersWithLastMessageTimestamp.getValue(identifier);
      const lastSeenSinceNow = (new Date).getTime() - lastSeen;

      if(lastSeenSinceNow > 5000) {
        // remove marker if we haven't seen a message for it for more then 5 seconds
        this.identifiersWithLastMessageTimestamp.remove(identifier);
        this.removeMarker(identifier);
      }
    });
  }

}

export class Intersection5GInfoLayer extends Generic5GInfoLayer {

  visible = false
  replayPrefix = '-replay'

  constructor(
    map: L.Map,
    client: MQTTConnector,
    private config: Config,
    private topics: string[],
    private regionPrecision: number,
    isLiveLayer: boolean
  ) {
    super(map, client, topics, isLiveLayer);
    if(this.isLiveLayer) { this.replayPrefix = '' }
  }

  onMarkerRemoval(identifier: string): void {}

  onMessage(message: MQTTMessage): void {
    let topic = message.topic
    let payload = JSON.parse(message.payloadString)
    const type = topic.split('/')[2];
    if (type.includes(MAPSTREAM)) { this.handleMapMessage(topic, payload); }
    if (type.includes(SPATSTREAM)) { this.handleSpatMessage(payload); }
  }

  handleMapMessage(topic: string, payload: any) {

    const tparts = topic.split('/');
    const id = tparts[tparts.length - 1];
    const spatTopic = `/tt/spat${this.replayPrefix}-decoded/${id}`
    this.mqttClient.subscribe(spatTopic);

    const intersection = payload.map.intersections[0];
    let intersectLat, intersectLong, intersectionIdentifier;
    intersectLat = intersection.refPoint.lat / COORDDIVIDER;
    intersectLong = intersection.refPoint.long / COORDDIVIDER;
    intersectionIdentifier = intersection.id.region + intersection.id.id;

    const camQuadkey = tparts
      .slice(3, tparts.length-1) // select quadkey from topic
      .slice(0, 12) // use quadkey lvl12 (getting lvl20)
      .join('') // stringify
    const mapQuadkeyParam = `?quadkey=${camQuadkey}`;
    const url = `${this.config.KPN_5G_3D_UI}${mapQuadkeyParam}&type=intersection&id=${payload.header.stationID}&live=${LIVE}`
    const popupInfo =
      `<p class='ndw-popup'>
        <b class='popup-title'>${intersection.name}</b><br/><br/>
        <b class='popup-text'>Region: ${intersection.id.region}</b><br/>
        <b class='popup-text'>ID: ${intersection.id.id}</b><br/><br/>
        <b class='popup-text'>Coordinates: ${intersectLat}, ${intersectLong}</b><br/><br/>
        <b class='popup-label'>No light information</b><br/>
        <h4>
          <a href="${url}" target="_blank">Detail view</a>
        </h4>
      </p>`

    // draw full intersection on zoom in
    // put in layer & toggle layer on zoom threshold
    // https://github.com/Klarrio/dsh-test-talkingtraffic-demo/issues/69
    const drawFullMap = false;
    if (drawFullMap) {
      // remove intersection center marker
      this.removeMarker(intersectionIdentifier);
      const intersectLat = intersection.refPoint.lat / COORDDIVIDER;
      const intersectLong = intersection.refPoint.long / COORDDIVIDER;

      // LOOP LANES
      const lanes = intersection.laneSet;
      lanes.forEach(lane => {
        // don't draw lanes that don't have lights
        if (!lane.connectsTo || !lane.connectsTo[0] || !lane.connectsTo[0].signalGroup) return;
        const type = lane.laneAttributes.laneType[0];
        const laneID = lane.laneID;
        const laneId = intersectionIdentifier + laneID;
        const laneIdString = laneId.toString();
        let prevLat = intersectLat;
        let prevLng = intersectLong;

        // LOOP ALL NODES OF A LANE
        lane.nodeList[1].forEach((node, i) => {
          const nodeDeltaX = node.delta[1].x;
          const nodeDeltaY = node.delta[1].y;
          // POSITION
          const diam52 = (2 * Math.PI * 637100000) * (Math.cos(prevLat * 2 * Math.PI / 360))
          const nodeLat = prevLat + ( (nodeDeltaY / 100 * 180) / (Math.PI * 6371000) );
          const nodeLng = prevLng + (nodeDeltaX * 360 / diam52);
          prevLat = nodeLat;
          prevLng = nodeLng;

          const nodeName = node.delta[0]

          // LANE ICONS
          if(node.attributes &&
             node.attributes.localNode &&
             node.attributes.localNode[0] === 'stopLine'
          ) {

            let icon;
            if (type === 'vehicle') icon = trafficLightIcon
            if (type === 'bikeLane') icon = bicycleLightIcon
            if (type === 'crosswalk') icon = crosswalkLightIcon

            this.setMarker(
              this.quadKeyFromTopic(topic, this.regionPrecision),
              laneIdString + nodeName + i,
              nodeLat,
              nodeLng,
              popupInfo,
              { icon },
              new Intersection5GInfoLayer.MapMarkerHandler(this.layer).handler
            );
            return; // don't render square of stop node
          }

          const laneColor = '#' + (lane.laneID**3).toString(16)
          const laneNodeSVG = `
          <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="100%" height="100%" fill="${laneColor}"/>
          </svg>`;
          const lanePopupInfo =
            `<p class='ndw-popup'>
              <b class='popup-title'>Lane: ${lane.laneID}</b><br/>
              <b class='popup-text'>Speed limit: XXX</b><br/>
            </p>`

          const icon = L.divIcon({ className: '', html: laneNodeSVG });
          this.setMarker(
            this.quadKeyFromTopic(topic, this.regionPrecision),
            laneIdString + nodeName + i,
            nodeLat,
            nodeLng,
            lanePopupInfo,
            { icon },
            new Intersection5GInfoLayer.MapMarkerHandler(this.layer).handler
          );

        });

      });
    } else { // ZOOM OUT VIEW
      const lanes = intersection.laneSet;
      lanes.forEach(lane => {
        const laneID = intersectionIdentifier + lane.laneID;
        const laneIdString = laneID.toString();
        this.removeMarker(laneIdString);
        lane.nodeList[1].forEach((node, i) => {
          this.removeMarker(laneIdString + node.delta[0] + i);
        })
      });

      this.setMarker(
        this.quadKeyFromTopic(topic, this.regionPrecision),
        intersectionIdentifier,
        intersectLat,
        intersectLong,
        popupInfo,
        {icon: trafficLightIcon},
        new Intersection5GInfoLayer.MapMarkerHandler(this.layer).handler
      );
    }

  }

  handleSpatMessage(payload: any) {
    const intersection = payload.spat.intersections[0];
    const identifier = intersection.region + intersection.id;

    let quadkeyOfSpat;
    this.markersByQuadKey.forEach((quadkey) => {
      this.markersByQuadKey.getValue(quadkey).forEach((gotID) => {
        if (identifier === gotID) quadkeyOfSpat = quadkey;
      })
    })
    const quadkeyParam = quadkeyOfSpat ? `?quadkey=${quadkeyOfSpat}&` : '?';
    const KPN_5G_3D_UI = this.config.KPN_5G_3D_UI ? this.config.KPN_5G_3D_UI : 'http://localhost:9000';
    const url = `${KPN_5G_3D_UI}${quadkeyParam}type=intersection&id=${payload.header.stationID}&live=${LIVE}`;

    const popupInfo =
      `<p class='ndw-popup'>
        <b class='popup-title'>${intersection.name}</b><br/><br/>
        <b class='popup-text'>Region: ${intersection.region}</b><br/>
        <b class='popup-text'>ID: ${intersection.id}</b><br/>
        <h4>
          <a href="${url}" target="_blank">Detail view</a>
        </h4>
      </p>`

    Try(() => intersection.states[1].color).foreach((color) =>
      this.setMarker(
          null,
          identifier,
          null,
          null,
          popupInfo,
          {},
          new Intersection5GInfoLayer.SpatMarkerHandler(this.layer, color).handler
      )
    );

  }

  static MapMarkerHandler = class {
    constructor(private layer: any) {
    }

    public handler = (quadkey: string, identifier: string, marker: TalkingTrafficMarker, options: L.RotatedMarkerOptions):void => {
      marker.quadkey = quadkey;
      marker.setIcon(options.icon);
      this.layer.addLayer(marker);
    }
  }

  static SpatMarkerHandler = class {
    constructor(private layer: any, private color: string) {
    }

    public handler = (quadkey: string, identifier: string, marker: TalkingTrafficMarker, options: L.RotatedMarkerOptions):void => {
      if (this.color !== 'green' &&
          this.color !== 'yellow' &&
          this.color !== 'red') {
        return;
      }
      marker.setIcon(
          L.icon({
            iconUrl: `/images/map-vehicle-${this.color}.png`,
            iconSize:     [30, 30],
            iconAnchor:   [15, 15],
            popupAnchor:  [5, -15]
          })
      )
    }
  }


}

