import * as $ from 'jquery'
import * as Collections from 'typescript-collections'

import { quadKey as QuadKey } from '../util/quadkey'
import { InfoLayer } from "./info-layer";
import { MQTTConnector, MQTTMessage } from "../dsh-mqtt";
import { Config } from "../config";
import { ndwMap, suppressedStreamsByQuadkey, accessibleLayerToggles, updateActiveEmergenciesList } from "../ndw-app";
import { forEach } from 'typescript-collections/dist/lib/arrays';
import {LayerToggle} from "./layer-toggle";

export class EmergencyInfoLayer extends InfoLayer {

  visible = true;
  emergencyQuadKeyBoundsLayers = new Collections.Dictionary<String, L.LayerGroup>();
  emergenciesById = new Collections.Dictionary<String, {}>();
  supressionTopic;

  constructor(
    map: L.Map,
    client: MQTTConnector,
    private config: Config,
    private regionPrecision: number
  ) {
    super(map, [config.mqtt.emergencyTopic, config.mqtt.suppressionsTopic], [], client);
    this.supressionTopic = `${config.mqtt.suppressionsTopic}/#`
  }

  subscribe() {
    this.mqttClient.subscribe(this.supressionTopic)
  }

  onMarkerRemoval(identifier: string): void { }

  onMessage(message: MQTTMessage): void {
    if (message.topic.includes('suppressions')) {
      this.handleSuppression(message);
    }
    if (message.topic.includes('prio-alerts')) {
      this.handlePrioAlert(message);
    }
  }

  handlePrioAlert(prioAlertMqttMessage) {
    const splitTopic = prioAlertMqttMessage.topic.split('/');
    const id = splitTopic.pop(); // remove emergency ID
    splitTopic.shift(); // remove empty element
    splitTopic.shift(); // remove tt
    splitTopic.shift(); // remove prio-alerts
    const key = splitTopic.join('')
    const layerIdentifier = `${id}+${key}`;
    if (prioAlertMqttMessage.payloadString.length > 0) {
      if (this.region.contains(key)) {
        if (!this.emergencyQuadKeyBoundsLayers.containsKey(layerIdentifier)) {
          this.drawEmergencyQuadkey(id, key)
        }
        if (!this.emergenciesById.containsKey(layerIdentifier)) {
          const emergency = JSON.parse(prioAlertMqttMessage.payloadString);
          this.emergenciesById.setValue(layerIdentifier, emergency);
        }
      } else {
        // emergency outside region
        this.emergenciesById.remove(layerIdentifier);
      }
    } else {
      // null message, remove red emergency quadkey
      if (this.emergencyQuadKeyBoundsLayers.containsKey(layerIdentifier)) {
        const layer = this.emergencyQuadKeyBoundsLayers.getValue(layerIdentifier);
        layer.clearLayers();
        this.emergencyQuadKeyBoundsLayers.remove(layerIdentifier);
      }
      const emergencyId = layerIdentifier.split('+')[0];
      this.cleanupActiveEmergenciesList(emergencyId);
    }
    updateActiveEmergenciesList(this.emergenciesById);
  }

  cleanupActiveEmergenciesList(emergencyId) {
    const toRemove = this.emergenciesById
      .keys()
      .find(identifier => identifier.includes(emergencyId))
    if (this.emergenciesById.containsKey(toRemove)) {
      this.emergenciesById.remove(toRemove);
      this.cleanupActiveEmergenciesList(emergencyId);
    }
  }

  handleSuppression(mqttMessage) {
    const splitTopic = mqttMessage.topic.split('/');
    const id = splitTopic.pop();
    if (mqttMessage.payloadString.length > 0) {
      const suppression = JSON.parse(mqttMessage.payloadString);
      suppression.quads.forEach(quadkey => {
        const identifier = `${id}+${quadkey.toString()}`;
        // new suppression
        if (!suppressedStreamsByQuadkey.containsKey(identifier)) {
          suppressedStreamsByQuadkey.setValue(identifier, suppression.streams);
          accessibleLayerToggles.map( l => l.infoLayers ).forEach((layers: Array<InfoLayer>) => {
            layers.forEach((infoLayer: InfoLayer) => {
              // update visible layers to unsubscribe & remove suppressed markers
              if (infoLayer.visible) {
                infoLayer.updateSubscriptionsAndMarkers()
              }
            })
          });
        }

      });
    } else {
      const suppressedStreams = suppressedStreamsByQuadkey
        .keys()
        .filter(identifier => identifier.includes(id))
      suppressedStreams.forEach(suppressedStream => {
        suppressedStreamsByQuadkey.remove(suppressedStream);
      });
      accessibleLayerToggles.map( l => l.infoLayers ).forEach((layers: Array<InfoLayer>) => {
        layers.forEach((infoLayer: InfoLayer) => {
          // update visible layers to resubscribe & re-add unsuppressed markers
          if (infoLayer.visible) {
            infoLayer.updateSubscriptionsAndMarkers()
          }
        })
      });
    }
  }

  updateSubscriptionsAndMarkers() {
    // visible, sub
    let newSubscriptions = new Collections.Set<String>()
    let newSubscriptionTopic 
    this.region.forEach((currentQuadKey: string) => {
      newSubscriptionTopic  = "/tt/prio-alerts/" + currentQuadKey.split('').join('/') + "/#"
      newSubscriptions.add(newSubscriptionTopic)
      this.mqttClient.subscribe(newSubscriptionTopic)
      this.currentSubscriptionTopics.add(newSubscriptionTopic)
      if (this.subscriptionUpdateCallback) {
        this.subscriptionUpdateCallback(newSubscriptionTopic);
      }
    })

    // unsubscribe from all quadkeys that fall outside the region
    this.currentSubscriptionTopics.forEach((topic: string) => {
      if(!newSubscriptions.contains(topic)) {
          this.mqttClient.unsubscribe(topic)
          this.currentSubscriptionTopics.remove(topic)
      }
    })

    this.emergencyQuadKeyBoundsLayers.keys().forEach(layerIdentifier => {
      const splitLayerIdentifier = layerIdentifier.split('+');
      const id = splitLayerIdentifier[0];
      const quadkey = splitLayerIdentifier[1];
      // outside of visible quadkeys, remove emergency quadkeys
      if(!this.region.contains(quadkey)) {
        const layer = this.emergencyQuadKeyBoundsLayers.getValue(layerIdentifier);
        layer.clearLayers();
        this.emergencyQuadKeyBoundsLayers.remove(layerIdentifier);
      }
    })
  }

  drawEmergencyQuadkey(id, key) {
    const quadKeyBoundsLayer = L.layerGroup([]).addTo(ndwMap);
    this.emergencyQuadKeyBoundsLayers.setValue(`${id}+${key}`, quadKeyBoundsLayer);
    const rawBounds = QuadKey.bbox(key.toString());
    const bounds = L.latLngBounds(
      L.latLng(rawBounds.minlat, rawBounds.minlng),
      L.latLng(rawBounds.maxlat, rawBounds.maxlng)
    );
    const geoBoundaryOverlay = L.rectangle(bounds, {
      color: 'red',
      fill: true,
      weight: 0,
      fillOpacity: 0.4
    });
    quadKeyBoundsLayer.addLayer(geoBoundaryOverlay);
  }


}
