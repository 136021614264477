import * as Collections from 'typescript-collections'
import { ParkingData, ParkingTimeRange } from '../spdp'
import * as L from 'leaflet'
import { MQTTConnector, MQTTMessage } from '../dsh-mqtt'
import { Config } from '../config'
import { InfoLayer } from './info-layer'

export class ParkingInfoLayer extends InfoLayer {
    visible = false
    parkings: Collections.Dictionary<String, ParkingData>
    v1GeoTopics: Array<string>
    v2GeoTopics: Array<string>
    v1NonGeoTopics: Array<string>
    v2NonGeoTopics: Array<string>

    constructor(map: L.Map, client: MQTTConnector, private config: Config, private regionPrecision: number) {
        super(
            map,
            config.mqtt.parkingGeoV1Topics.split(',').concat(config.mqtt.parkingGeoV2Topics.split(',')),
            config.mqtt.parkingNonGeoV1Topics.split(',').concat(config.mqtt.parkingNonGeoV2Topics.split(',')),
            client
        )
        this.parkings = new Collections.Dictionary<String, ParkingData>()
        this.v1GeoTopics = config.mqtt.parkingGeoV1Topics.split(',')
        this.v2GeoTopics = config.mqtt.parkingGeoV2Topics.split(',')
        this.v1NonGeoTopics = config.mqtt.parkingNonGeoV1Topics.split(',')
        this.v2NonGeoTopics = config.mqtt.parkingNonGeoV2Topics.split(',')
    }

    onMarkerRemoval(identifier: string): void {
        let pdata = this.parkings.getValue(identifier)
        this.parkings.remove(identifier)
        if (pdata) {
            console.log(`unsubscribing from ${pdata.dynamicDataTopic}`)
            this.mqttClient.unsubscribe(pdata.dynamicDataTopic)
        }
    }

    updateParkingData(message: any): ParkingData {
        let pdata: ParkingData = null
        let isNewParking = false
        let id = ParkingData.idFromTopic(message.topic)

        if (message.topic.search(/spdp-stat_.p_v2/) != -1) {
            // new static description data v2
            pdata = this.parkings.getValue(id)
            if (pdata) {
                pdata.parseStaticDataV2(message.payloadString, message.topic)
            } else {
                pdata = new ParkingData()
                if (!pdata.parseStaticDataV2(message.payloadString, message.topic)) return null
                this.parkings.setValue(id, pdata)
                pdata.quad = this.quadKeyFromTopic(message.topic, this.regionPrecision)
                isNewParking = true
            }
        } else if (message.topic.search(/spdp-stat_.p_v1/) != -1) {
            // new static description data v1
            pdata = this.parkings.getValue(id)
            if (pdata) {
                pdata.parseStaticDataV1(message.payloadString, message.topic)
            } else {
                pdata = new ParkingData()
                if (!pdata.parseStaticDataV1(message.payloadString, message.topic)) return null
                this.parkings.setValue(id, pdata)
                pdata.quad = this.quadKeyFromTopic(message.topic, this.regionPrecision)
                isNewParking = true
            }
        } else if (message.topic.search(/spdp-dyn_.p_v2/) != -1) {
            pdata = this.parkings.getValue(id)
            if (pdata) {
                pdata.parseDynamicDataV2(message.payloadString)
            }
        } else if (message.topic.search(/spdp-dyn_.p_v1/) != -1) {
            pdata = this.parkings.getValue(id)
            if (pdata) {
                pdata.parseDynamicDataV1(message.payloadString)
            }
        }

        if (isNewParking) {
            // subscribe to the dynamic data topic for this parking
            console.log(`subscribing to ${pdata.dynamicDataTopic}`)
            this.mqttClient.subscribe(pdata.dynamicDataTopic)
        }

        return pdata
    }

    onMessage(message: MQTTMessage): void {
        let topic = message.topic
        let pdata = this.updateParkingData(message)
        if (!pdata) {
            console.error(`could not decode incoming message: ${topic} - ${message.payloadString}`);
            return
        }

        let identifier = pdata.id

        let sigils = ''
        let iconSuffix = ''
        if (pdata.lastUpdate != 0) {
            if (pdata.open) {
                sigils = 'opened'
                iconSuffix = 'available'
            } else {
                sigils = 'closed'
                iconSuffix = 'closed'
            }
            if (pdata.full) {
                sigils += ', full'
                iconSuffix = 'full'
            }
        } else {
            sigils = 'no current data available'
            iconSuffix = 'static'
        }
        let popupInfo = `<div class='parking-popup' style='overflow: auto; max-height: 300px;'>
                        <b>${pdata.name}</b> [${sigils}]<br/>
                        <b>Usage</b> ${pdata.usage}<br/>`
        if (pdata.capacity != -1) {
            if (pdata.vacantSpaces != -1) {
                popupInfo += `<b>Free capacity</b> ${pdata.vacantSpaces}/${pdata.capacity}<br/>`
            } else {
                popupInfo += `<b>Capacity</b> ${pdata.capacity}<br/>`
            }
        }
        if (pdata.minHeight != -1) {
            popupInfo += `<b>Max vehicle height</b> ${pdata.minHeight}m<br/>
`
        }
        if (pdata.entrances.length > 0) {
            popupInfo += `<b>Vehicle entrances</b><br/>`
            pdata.entrances.map(e => {
                popupInfo += `&nbsp;${e}<br/>`
            })
        }

        popupInfo += `<b>Opening times</b><br/>`
        pdata.openingTimes.forEach((k: string, v: ParkingTimeRange) => {
            popupInfo += `&nbsp;${k}: ${v.toHTML()}<br/>`
        })

        if (pdata.tariffs.length > 0) {
            popupInfo += `<b>Tariffs</b><br/>`
            for (let tariff of pdata.tariffs) {
                popupInfo += `&nbsp;on ${tariff.days.join(', ')} (${tariff.validHours.toHTML()}):<br/>`
                for (let interval of tariff.intervals) {
                    popupInfo += `&nbsp;&nbsp;&nbsp;${interval.toHTML()}<br/>`
                }
                if (tariff.maximumDayCharge != -1) {
                    popupInfo += `&nbsp;&nbsp;&nbsp;&nbsp;maximum day charge is &euro;${tariff.maximumDayCharge}<br/>`
                }
            }
        }

        popupInfo += `</div>`

        let icon = L.icon({
            iconUrl: `/images/parking-${iconSuffix}.png`,
            iconSize: [24, 30], // size of the icon
            iconAnchor: [12, 15], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -15] // point from which the popup should open relative to the iconAnchor
        })
        if (pdata.lastUpdate) {
            let timestamp = new Date(pdata.lastUpdate * 1000)
            popupInfo += `<b class='popup-text'>${timestamp.toString()}</b>`
        }

        this.setMarker(pdata.quad, identifier, pdata.lat, pdata.lng, popupInfo, {icon})
    }
}
