import { InfoLayer } from './info-layer'
import * as L from 'leaflet'
import { MQTTConnector, MQTTMessage } from '../dsh-mqtt'
import { Config } from '../config'

export class OvBicycleInfoLayer extends InfoLayer {
    visible = false

    constructor(map: L.Map, client: MQTTConnector, private config: Config, private regionPrecision: number) {
        super(map, [config.mqtt.ovBicycleTopic], [], client)
    }

    onMarkerRemoval(identifier: string): void {
    }

    onMessage(message: MQTTMessage): void {
        let topic = message.topic
        let payload = JSON.parse(message.payloadString)

        let splitTopic = topic.split('/')
        let station = splitTopic[splitTopic.length - 2]
        let location = splitTopic[splitTopic.length - 1]

        let nbikes = payload.rentalBikes != null ? payload.rentalBikes : 'n/a'
        let open = payload.open ? 'yes' : 'no'
        let popupInfo = `<p class='ndw-popup'>${payload.description}</p>
                        <p class='ndw-popup'>number of bikes: ${nbikes}</p>
                        <p class='ndw-popup'>open: ${open}</p>`

        let icon = L.icon({
            iconUrl: `/images/ovfiets.png`,
            iconSize: [36, 36], // size of the icon
            iconAnchor: [18, 36], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -15], // point from which the popup should open relative to the iconAnchor
            className: payload.rentalBikes == null ? 'ndw-no-ov-bike-counter' : ''
        })

        let identifier = `${station}:${location}`

        this.setMarker(this.quadKeyFromTopic(topic, this.regionPrecision), identifier, payload.lat, payload.lng, popupInfo, {icon})
    }
}
