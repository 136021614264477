/// <reference path="../types/leaflet-providers.d.ts" />
/// <reference path="../types/vectorgrid.d.ts" />

import * as $ from 'jquery'
import * as L from 'leaflet'

import { quadKey as QuadKey } from './util/quadkey'
import * as LeafletProviders from 'leaflet-providers'
import * as Collections from 'typescript-collections'
import { Authenticator } from './authenticator'
import { MQTTConnector, MQTTMessage, MQTTState, MqttTopicParser } from './dsh-mqtt'
import { EnvWindow } from './config'
import {
    Checkbox5GLayerToggle,
    EmergencyLayerVirtualToggle,
    LayerToggle,
    Radio5GLayerToggle,
    RailLayerToggle
} from "./layers/layer-toggle";
import { InfoLayer } from './layers/info-layer'

import {
  Intersection5GInfoLayer,
  CAM5GInfoLayer,
} from './layers/5g/5G-info-layer';
import {LatLngExpression, Layer, map} from 'leaflet';
import { EmergencyInfoLayer } from './layers/emergency-info-layer';
import { RailInfoLayer, TrainInfoLayer } from './layers/rail-info-layer'
import { WeatherInfoLayer } from './layers/weather-info-layer'
import { BicycleParkingInfoLayer} from './layers/bicycle-parking-info-layer'
import { OvBicycleInfoLayer } from './layers/ov-bicycle-info-layer'
import { TrafficInfoLayer } from './layers/ndw-traffic-info-layer'
import { Kv6InfoLayer } from './layers/kv6-bus-tram-metro-info-layer'
import { AirPollutionInfoLayer } from './layers/air-pollution-info-layer'
import { ParkingInfoLayer } from './layers/parking-info-layer'
import {SatelliteInfoLayer} from "./layers/satellite-info-layer";

//Declare these types to force import
LeafletProviders

//load styles
require('./stylesheets/main.less')
require('./stylesheets/utilities.less')

/********************************************************************
 * APPLICATION LOGIC                                                *
 ********************************************************************/
let config = (<EnvWindow> window).__env;

let REGION_PRECISION = 12
const startZoom = 11;
let zoomLevel = 1.5 * startZoom;
const EindhovenLat = 51.4725, EindhovenLng = 5.59;
const EindhovenCoords = [EindhovenLat, EindhovenLng] as LatLngExpression;
let ndwMap = L.map('ndw-map').setView(EindhovenCoords, startZoom)

// create the tile layer with correct attribution
const HiDPItilesUrl = `https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=${config.auth.mapKey}`;
const satelliteTilesUrl = `https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=${config.auth.mapKey}`;
let osmAttribution = '<a href="https://www.freepik.com/free-photos-vectors/car">Car vector designed by Freepik</a> | '
let railOsmUrl='https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png'
let railOsmAttribution = `Data <a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>, Style: <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA 2.0</a> <a href="https://www.openrailwaymap.org/">OpenRailwayMap</a> and OpenStreetMap`
let railOsm = L.tileLayer(railOsmUrl, {minZoom: 8, maxZoom: 19, attribution: railOsmAttribution}).setOpacity(0.75)
let HiDPIlayer = L.tileLayer(HiDPItilesUrl, {minZoom: 8, maxZoom: 28, maxNativeZoom: 23})
let satellitelayer = L.tileLayer(satelliteTilesUrl, {minZoom: 8, maxZoom: 20, maxNativeZoom: 20})
const suppressedStreamsByQuadkey = new Collections.Dictionary<String, Array<String>>();

// Add layer to map
ndwMap.addLayer(HiDPIlayer);

let quadKeyBoundsLayer = L.layerGroup([]).addTo(ndwMap)
let emergencyQuadKeyBoundsLayers = new Collections.Dictionary<String, L.LayerGroup>();

ndwMap.on('moveend', () => {
  selectedQuadKey = null
  updateQuadKeyInfo(selectedQuadKey)
  adjustDataStream()
  updateActiveEmergenciesList()
})
ndwMap.on('zoomend', function() {
  zoomLevel = 2 * (ndwMap.getZoom());
});

const cities = [
  { name: 'Eindhoven', lat: EindhovenLat, lng: EindhovenLng },
  { name: 'Den Haag', lat: 52.07, lng: 4.45 },
  { name: 'Amsterdam', lat: 52.3667, lng: 4.95 },
  { name: 'Deventer', lat: 52.25, lng: 6.275 },
  { name: 'Rotterdam', lat: 51.924, lng: 4.53 },
  { name: 'Antwerpen', lat: 51.22, lng: 4.47 },
];
const citiesDropdown = $("#cities");
citiesDropdown.append(cities
    .map(city => $("<option>")
      .text(city.name)
      .attr("value", JSON.stringify(city))
    )
)

citiesDropdown.on('change', () => {
  const selectedCity = JSON.parse($("#cities").val());
  ndwMap.flyTo(L.latLng(selectedCity.lat, selectedCity.lng));
});

let logoutButton = $('#btn-logout')
logoutButton.click((e: Event) => {
    e.preventDefault()
    authenticator.logout()
})

let showHideDebug = $("#debug-console-button")
showHideDebug.click((e: Event) => {
    e.preventDefault()
    $(".debug-console-wrapper").toggle();
})
let sideMenuOpen = true
$('.toggleMenu').click(() => {
  sideMenuOpen = !sideMenuOpen
  $('#nav-icon').toggleClass('open');
  $('#ndw-info-panel').toggle();
  adjustDataStream()
});

$('#toggle-satellite').click(() => {
  // if zoomlevel > 20, zoom to 20
  ndwMap.removeLayer(HiDPIlayer);
  ndwMap.addLayer(satellitelayer);
})
$('#toggle-map').click(() => {
  ndwMap.removeLayer(satellitelayer);
  ndwMap.addLayer(HiDPIlayer);
})

let mqtt = new MQTTConnector({
    tenantApiUrl: config.auth.tenantAuthUrl,
    onMessage: onMessageArrived,
    onStateChange: onMQTTStateChange
})

let availableLayerToggles = new Collections.LinkedList<LayerToggle>()
let accessibleLayerToggles = new Array<LayerToggle>()

availableLayerToggles.add(new LayerToggle('#traffic',
    [new TrafficInfoLayer(ndwMap, mqtt, config, REGION_PRECISION)] ))
availableLayerToggles.add(new LayerToggle('#weather',
    [new WeatherInfoLayer(ndwMap, mqtt, config, REGION_PRECISION)] ))
availableLayerToggles.add(new LayerToggle('#parking',
    [new ParkingInfoLayer(ndwMap, mqtt, config, REGION_PRECISION)] ))
availableLayerToggles.add(new LayerToggle('#ov-bicycle',
    [new OvBicycleInfoLayer(ndwMap, mqtt, config, REGION_PRECISION)] ))
availableLayerToggles.add(new LayerToggle('#kv6',
    [new Kv6InfoLayer(ndwMap, mqtt, config, REGION_PRECISION)] ))
availableLayerToggles.add(new LayerToggle('#bicycle-parking',
    [new BicycleParkingInfoLayer(ndwMap, mqtt, config, REGION_PRECISION)] ))
availableLayerToggles.add(new LayerToggle('#airpollution',
    [new AirPollutionInfoLayer(ndwMap, mqtt, config, REGION_PRECISION)] ))
availableLayerToggles.add(new LayerToggle('#satellite',
    [new SatelliteInfoLayer(ndwMap, mqtt, config, REGION_PRECISION)] ))

// Rail
availableLayerToggles.add(new RailLayerToggle('#rail',
    new RailInfoLayer(ndwMap, mqtt, config, REGION_PRECISION),
    new TrainInfoLayer(ndwMap, mqtt, config, REGION_PRECISION), railOsm))

// Emergency
availableLayerToggles.add( new EmergencyLayerVirtualToggle('#emergency',
    [ new EmergencyInfoLayer(ndwMap, mqtt, config, REGION_PRECISION)] ))

// 5G
let cam = new CAM5GInfoLayer(ndwMap, mqtt, config, [config.mqtt.CAM5GTopic], REGION_PRECISION, false)
let camLive = new CAM5GInfoLayer(ndwMap, mqtt, config, [config.mqtt.CAMLIVE5GTopic], REGION_PRECISION, true)
let intersection = new Intersection5GInfoLayer(ndwMap, mqtt, config, [config.mqtt.MAP5GTopic, config.mqtt.SPAT5GTopic], REGION_PRECISION, false)
let intersectionLive =     new Intersection5GInfoLayer(ndwMap, mqtt, config, [config.mqtt.MAPLIVE5GTopic, config.mqtt.SPATLIVE5GTopic], REGION_PRECISION, true)
availableLayerToggles.add(new Radio5GLayerToggle('#live', cam, camLive, intersection, intersectionLive, adjustDataStream, true))
availableLayerToggles.add(new Radio5GLayerToggle('#replay', cam, camLive, intersection, intersectionLive, adjustDataStream, false))

/* kick authentication logic */
let authenticator = new Authenticator({
    appid: 'ndw',
    domain: config.auth.domain,
    clientId: config.auth.clientId,
    audience: config.auth.audience,
    scope: 'read:ndw read:parking read:railsense read:5gdemo read:emergency',
    onStateChange: onAuthenticationStateChange
})

authenticator.handleAuthentication()

function onAuthenticationStateChange(isAuthenticated: boolean, accessToken: string): void {
    if (isAuthenticated) {
        mqtt.setAuthentication(accessToken)
    } else {
        mqtt.setAuthentication('')
        authenticator.login() // force the login page
    }
}

function onMQTTStateChange(state: MQTTState) {
    if (state == MQTTState.Connected) {
        adjustDataStream()
        updateLayerAndToggleAccessibility()
        showCheckboxesAndSetOnChange()
        setOnSubscriptionUpdate()
    }

    $('#mqttState').text(MQTTState[state])
    if (MQTTState[state] === 'Connected') {
      $('#mqttState').css('color', '#009900')
    } else {
      $('#mqttState').css('color', '#FF3333')
    }
}

function checkLayerAccessibility(layer: InfoLayer) {
    return checkMqttClaim(layer.geoBaseTopics) || checkMqttClaim(layer.nonGeoBaseTopics)
}

function checkLayerToggleAccessibility(toggle: LayerToggle) {
    return toggle.infoLayers.some((layer: InfoLayer) => {
        return checkLayerAccessibility(layer)
    })
}

function updateLayerAndToggleAccessibility(): void {
    availableLayerToggles.forEach((toggle: LayerToggle) => {
        if (checkLayerToggleAccessibility(toggle)) {
            accessibleLayerToggles.push(toggle)
        }

        toggle.infoLayers.forEach((layer: InfoLayer) => {
            layer.isAccessible = checkLayerAccessibility(layer);
        });
    })
}

function onMessageArrived(message: MQTTMessage): void {
    accessibleLayerToggles.map( l => l.infoLayers ).forEach((layers: Array<InfoLayer>) => {
        layers.forEach((layer: InfoLayer) => {
            if (layer.wantMessage(message) && layer.visible) {
                layer.onMessage(message)
            }
        })
    })
}

function checkMqttClaim(topics): boolean {
  let allowed = false;
  topics.forEach(topic => {
    if (mqtt && mqtt.mqttToken) {
      allowed = mqtt.mqttToken.claims
        .some(claim => claim.resource.stream === MqttTopicParser.getStream(topic))
    }
  });
  return allowed;
}

function showCheckboxesAndSetOnChange() {
    accessibleLayerToggles.forEach((toggle: LayerToggle) => {
        toggle.showCheckbox()
        toggle.setOnChange()
    })
}

function setOnSubscriptionUpdate() {
    accessibleLayerToggles.forEach((toggle: LayerToggle) => {
        toggle.infoLayers.forEach((layer: InfoLayer) => {
            layer.onSubscriptionUpdate(onSubscriptionUpdate)
            if (layer.visible) {
                adjustDataStream()
                layer.updateSubscriptionsAndMarkers()
            }
        })
    })
}

/**
 * Subscribes on a region of data around the center of the map.
 */
let quadkeys = new Collections.Set<String>()
function adjustDataStream(): void {
    quadkeys = new Collections.Set<String>()
    let mapCenter = ndwMap.getCenter()
    const lng = sideMenuOpen ? mapCenter.lng - 0.115 : mapCenter.lng;
    let quadkey = QuadKey.latLongToQuadKey(mapCenter.lat, lng, REGION_PRECISION)
    quadkeys.add(quadkey)
    $.each(quadKeyNeighbours(quadkey), (key: string, quadKeyValue: string) => {
        quadkeys.add(quadKeyValue)
    })

    accessibleLayerToggles.map( l => l.infoLayers).forEach((layers: Array<InfoLayer>) => {
        layers.forEach((layer: InfoLayer) => {
            layer.setVisibleRegion(quadkeys)
        })
    })

    adjustQuadKeyBoundsOverlay(quadkeys)
}

function quadKeyNeighbours(quadKey: string): Array<string> {
    let NORTH = 1
    let SOUTH = -1
    let EAST = 1
    let WEST = -1
    let CENTER = 0
    return [
        QuadKey.neighbour(quadKey, NORTH, CENTER),
        QuadKey.neighbour(quadKey, NORTH, EAST),
        QuadKey.neighbour(quadKey, CENTER, EAST),
        QuadKey.neighbour(quadKey, SOUTH, EAST),
        QuadKey.neighbour(quadKey, SOUTH, CENTER),
        QuadKey.neighbour(quadKey, SOUTH, WEST),
        QuadKey.neighbour(quadKey, CENTER, WEST),
        QuadKey.neighbour(quadKey, NORTH, WEST),
    ]
}
const quadKeyField = $("#ndw-info-panel .quadKey");
const totalDataPointsField = $("#ndw-info-panel .quadKeyDatapoints");
function updateQuadKeyInfo(quadkey: string): void {
  if (!selectedQuadKey) {
    quadKeyField.text('none selected');
    totalDataPointsField.text('n/a');
    return;
  }
  if (!quadkey) { return; }
  let key = quadkey ? quadkey : selectedQuadKey;
  quadKeyField.text(key);
  let totalDataPoints = 0
  accessibleLayerToggles.map( l => l.infoLayers).forEach((layers: Array<InfoLayer>) => {
      layers.forEach((layer: InfoLayer) => {
          if(layer.markersByQuadKey.containsKey(key)) {
              totalDataPoints += layer.markersByQuadKey.getValue(key).size();
          }
      })
 })
 totalDataPointsField.text(totalDataPoints);
}
setInterval(updateQuadKeyInfo, 200);

let selectedQuadKey = null
function adjustQuadKeyBoundsOverlay(quadKeys: Collections.Set<String>): void {
    quadKeyBoundsLayer.clearLayers()

    quadKeys.forEach((quadKey: string) => {
        let rawBounds = QuadKey.bbox(quadKey)
        let bounds = L.latLngBounds(L.latLng(rawBounds.minlat, rawBounds.minlng), L.latLng(rawBounds.maxlat, rawBounds.maxlng))
        let geoBoundaryOverlay = L.rectangle(bounds, {
            color: "#394241",
            fill: true,
            weight: 3,
            opacity: 0.7,
            fillOpacity: 0.0
        })
        geoBoundaryOverlay.on('click', () => {
            selectedQuadKey = quadKey
            updateQuadKeyInfo(selectedQuadKey)
        })
        quadKeyBoundsLayer.addLayer(geoBoundaryOverlay)
    })
}

function onSubscriptionUpdate(newSubscriptionTopic: string): void {
    let debugConsole = $("#debug-console");
    debugConsole.text(debugConsole.text()
        + "\n"
        + new Date().toLocaleString() + " "
        + "Subscribed on: " + newSubscriptionTopic)
    debugConsole.scrollTop(debugConsole[0].scrollHeight)
}

let emergenciesByIdCollection = new Collections.Dictionary<String, {}>();
function updateActiveEmergenciesList(emergenciesById = null) {
  if (emergenciesById) emergenciesByIdCollection = emergenciesById
  const emergenciesInRegion = [];
  emergenciesByIdCollection.forEach((identifier, prioAlert: any) => {
    quadkeys.forEach(regionKey => {
      const emergencyKey = identifier.split('+')[1]
      if (regionKey === emergencyKey
        && !emergenciesInRegion.some(e => e.message === prioAlert.message)) {
          emergenciesInRegion.push(prioAlert)
        }
    });
  })
  const emergenciesElement = $('#emergencies');
  emergenciesElement.html('');
  if (emergenciesInRegion.length > 0) {
    emergenciesElement.append('<h2 style="color: red">Active emergencies</h2>')
    $.each(emergenciesInRegion, i => {
      $.each(emergenciesInRegion[i], (key, value) => {
        if (key === 'message') emergenciesElement.append(`<p>${value}</p>`)
      })
    })
  }
}

export {
    ndwMap,
    zoomLevel,
    suppressedStreamsByQuadkey,
    accessibleLayerToggles,
    updateActiveEmergenciesList
};
