import { InfoLayer } from "./info-layer";
import { ndwMap } from "../ndw-app";
import {RailInfoLayer, TrainInfoLayer} from "./rail-info-layer";
import * as L from 'leaflet'
import * as $ from 'jquery'
import {CAM5GInfoLayer, Generic5GInfoLayer, Intersection5GInfoLayer} from "./5g/5G-info-layer";
import {EmergencyInfoLayer} from "./emergency-info-layer";

export class LayerToggle {
    layerId: String
    infoLayers: Array<InfoLayer>

    constructor(id: String, layers: Array<InfoLayer>) {
        this.layerId = id
        this.infoLayers = layers
        $(`${this.layerId}-layer`).addClass('hidden')    }

    showCheckbox() {
        $(`${this.layerId}-layer`).removeClass('hidden')
    }

    setOnChange() {
        $( `${this.layerId}-visibility` ).on("change", () => {
            this.infoLayers.forEach((layer: InfoLayer) => {
                if (layer.isAccessible) {
                    layer.setVisible( $( `${this.layerId}-visibility`  ).prop('checked'))
                }
            })
        })
    }
}

export class RailLayerToggle extends LayerToggle {

    railInfoLayer: RailInfoLayer
    trainInfoLayer: TrainInfoLayer
    railOsm: L.TileLayer

    constructor(id: String, rail: RailInfoLayer, train: TrainInfoLayer, osm: L.TileLayer) {
        super(id, [rail, train])
        this.railInfoLayer = rail
        this.trainInfoLayer = train
        this.railOsm = osm
    }

    setOnChange() {
        $(`${this.layerId}-visibility` ).on("change", () => {
            let isChecked = $(`${this.layerId}-visibility`).prop('checked')
            this.infoLayers.forEach((layer: InfoLayer) => {
                if (layer.isAccessible) {
                    layer.setVisible(isChecked)
                    if (layer.visible) {
                        ndwMap.addLayer(this.railOsm)
                    }
                    else {
                        ndwMap.removeLayer(this.railOsm)
                        if (layer instanceof TrainInfoLayer) {
                            layer.removeAllLines()
                        }
                    }
                }
            })
        })
    }
}

export class EmergencyLayerVirtualToggle extends LayerToggle {

    emergencyLayer: Array<EmergencyInfoLayer>

    constructor(id: String, layers: Array<EmergencyInfoLayer>) {
        super(id, layers);
        this.emergencyLayer = layers
    }

    showCheckbox() {
        this.subscribe()
    }

    setOnChange() {}

    private subscribe() {
        this.emergencyLayer.forEach((layer: EmergencyInfoLayer) => {
            if (layer.isAccessible) {
                layer.subscribe()
            }
        })
    }
}

export let LIVE = true
export class Radio5GLayerToggle extends LayerToggle {

    adjustDataStreamCallback: () => void
    cam5GCheckbox: Checkbox5GLayerToggle
    intersection5GCheckbox: Checkbox5GLayerToggle
    isLiveRadio;

    constructor(id: String, cam: CAM5GInfoLayer, camLive: CAM5GInfoLayer, intersection: Intersection5GInfoLayer,
                intersectionLive: Intersection5GInfoLayer, callback, liveRadio) {
        super(id, [cam, camLive, intersection, intersectionLive])
        this.cam5GCheckbox = new Checkbox5GLayerToggle('#cam5G', cam, camLive, callback)
        this.intersection5GCheckbox = new Checkbox5GLayerToggle('#intersection5G', intersection, intersectionLive, callback)
        this.adjustDataStreamCallback = callback
        this.isLiveRadio = liveRadio
    }

    showCheckbox() {
        this.infoLayers.forEach((layer: Generic5GInfoLayer) => {
            if (layer.isLiveLayer === this.isLiveRadio && layer.isAccessible) {
                $(`${this.layerId}-layer`).removeClass('hidden')
                this.cam5GCheckbox.showCheckbox()
                this.intersection5GCheckbox.showCheckbox()
            }
        })
    }

    setOnChange() {
        this.cam5GCheckbox.setOnChange()
        this.intersection5GCheckbox.setOnChange()
        $( `${this.layerId}-visibility` ).on("change", () => {
            this.infoLayers.forEach((layer: Generic5GInfoLayer) => {
                if (layer.isAccessible) {
                    this.cam5GCheckbox.checkVisibility()
                    this.intersection5GCheckbox.checkVisibility()
                }
                else {
                    layer.setVisible(false)
                }
            })
        })
    }
}

export class Checkbox5GLayerToggle extends LayerToggle {

    adjustDataStreamCallback: () => void

    constructor(id: String, replay: InfoLayer, live: InfoLayer, callback) {
        super(id, [replay, live]);
        this.adjustDataStreamCallback = callback
    }

    showCheckbox() {
        this.infoLayers.forEach((layer: Generic5GInfoLayer) => {
            if (layer.isAccessible) {
                $(`${this.layerId}-layer`).removeClass('hidden')
                $('#5G-layers').removeClass('hidden')
            }
        })
    }

    setOnChange() {
        $( `${this.layerId}-visibility` ).on("change", () => { this.checkVisibility() })
    }

    checkVisibility() {
        LIVE = $('#live-visibility').prop('checked')
        const showLayer = $(`${this.layerId}-visibility`).prop('checked')
        this.infoLayers.forEach((layer: Generic5GInfoLayer) => {
            if (layer.isAccessible && showLayer) {
                if (LIVE && layer.isLiveLayer) {
                    layer.setVisible(LIVE)
                }
                if (LIVE && !layer.isLiveLayer) {
                    layer.setVisible(!LIVE)
                }
                if (!LIVE && layer.isLiveLayer) {
                    layer.setVisible(LIVE)
                }
                if (!LIVE && !layer.isLiveLayer) {
                    layer.setVisible(!LIVE)
                }
            }
            else {
                layer.setVisible(false)
            }
        })
    }
}